import Logger from './Logger';
import { markServerInternalError } from './TextUtils';

/**
 * Asynchronous methods that download files and provide their content as proper structures.
 */


// ----------------- public API --------------------------------------------------------------

/**
 * Return a promise that downloads the controller configuration.
 */
 export function downloadControllerConfig(log: Logger) : Promise<ControllerConfiguration> {
  const fullConfigFileName = `./controller/config.json`;
  return sendJsonDownloadRequest(fullConfigFileName).then(
    (response) => {
      if(!isControllerConfiguration(response)) {
        throw new Error(`Die Konfiguration, die der Server schickt, ist ungültig: ${JSON.stringify(response)}`);
      }
      log.log(`Received controller configuration`, response);
      return response;
    },
    (reject) => {
        throw new Error(markServerInternalError(`Wir können die Konfiguration nicht runterladen: ${reject.message}`, reject.isServerInternalError));
    });

}


/**
 * Return a promise that downloads the item configuration from the given URL.
 * 
 * We use the given item name for feedback messages only.
 */
export function downloadItemConfig(itemName: string, url: string, log: Logger) : Promise<ItemConfiguration> {
  return sendJsonDownloadRequest(url).then(
    (response) => {
      if(!isItemConfiguration(response)) {
        throw new Error(`Der Inhalt ${itemName}, den der Server von ${url} schickt, is ungültig: ${JSON.stringify(response)}`);
      }
      log.log(`Received configuration of ${itemName}`, response);
      return response;
    },
    (reject) => {
        throw new Error(markServerInternalError(`Wir können den Inhalt ${itemName} nicht runterladen: ${reject.message}`, reject.isServerInternalError));
    });
}


/**
 * The content of an item configuration file (as far as we need it).
 */
export interface ItemConfiguration {
  runtimeCompatibilityVersion: string,
  name: string
}

/**
 * The content of the controller configuration file.
 */
export interface ControllerConfiguration {
  controllerVersion: string,
  serverAccess: ServerAccess,
  mathJaxCdnUrl?: string,
  itemSize?: ItemSize,
  oidcConfig : OidcConfig,
  directLogOutUrl: string | undefined,
  useMockAuthentication?: boolean,
  additionalQuotesAroundSentJsonData?: boolean  
}

/**
 * The configuration of the Openid Connect authentication mechanism.
 */
export interface OidcConfig {
  authority: string, 
  clientId: string, 
  clientSecret: string,
  redirectUri: string
}

/**
 * The total display size of the CBA runtime.
 */
export interface ItemSize {
  height: number,
  width: number
}

/**
 * The details of the server access declaration in the configuration file.
 */
export interface ServerAccess {
  loginUrl: string,
  traceUrl: string
}

// ----------------- private stuff --------------------------------------------------------------

/**
 * Return a Promise that processes a GET request for the given file.
 */
function sendJsonDownloadRequest(filename : string) : Promise<any> {
  return new Promise((resolve, reject) => {
    const xhttp = new XMLHttpRequest();
    xhttp.responseType = 'json';
    xhttp.onload = (event) => { 
      if (xhttp.status !== 200) {
        reject({
          isServerInternalError: xhttp.status >= 500,
          message: `Der Download von ${filename} ergab den Status ${xhttp.status} mit dem Status-Text: ${xhttp.statusText} (Event ${event.type}: ${event.loaded} Bytes übertragen.)`         
        })
      } else {
        resolve(xhttp.response)
      }
    };
    xhttp.onerror = (event) => {
      const message = 
        xhttp.status === 0 && event.loaded === 0
        ? `Der Download von ${filename} schlug fehl. Vermutlich ist der Server von deinem Rechner aus nicht erreichbar.`         
        : `Der Download von ${filename} schlug fehl mit dem Status ${xhttp.status} und dem Status-Text: ${xhttp.statusText} (Event ${event.type}: ${event.loaded} Bytes übertragen.)`
      reject({
        isServerInternalError: xhttp.status >= 500,
        message
      });
    }    
    xhttp.open('GET', filename, true);
    xhttp.send();
  });
}


/**
 * Runtime type checker for ItemConfiguration candidates.
 * 
 * We just check some 'marker' members. 
 */
 function isItemConfiguration(candidate: any) : candidate is ItemConfiguration {  
  try {
    return (
      typeof candidate.runtimeCompatibilityVersion === 'string' &&
      typeof candidate.name === 'string'
    );
  }
  catch(error) {
    return false;
  }
}

/**
 * Runtime type checker for ControllerConfiguration candidates.
 */
 function isControllerConfiguration(candidate: any) : candidate is ControllerConfiguration {  
  try {
    return (
      typeof candidate.controllerVersion === 'string'
      && candidate.serverAccess && isServerAccess(candidate.serverAccess)
      && (candidate.mathJaxCdnUrl === undefined || typeof candidate.mathJaxCdnUrl === 'string') 
      && (candidate.itemSize === undefined || isItemSize(candidate.itemSize))
      && candidate.oidcConfig && isOidcConfig(candidate.oidcConfig)
      && (candidate.directLogOutUrl === undefined || typeof candidate.directLogOutUrl === 'string')
      && (candidate.useMockAuthentication === undefined || typeof candidate.useMockAuthentication === 'boolean')
      && (candidate.additionalQuotesAroundSentJsonData === undefined || typeof candidate.additionalQuotesAroundSentJsonData === 'boolean')
    );
  }
  catch(error) {
    return false;
  }
}

function isServerAccess(candidate: any) : candidate is ServerAccess {  
  try {
    return (
      typeof candidate.loginUrl === 'string' &&
      typeof candidate.traceUrl === 'string' 
    );
  }
  catch(error) {
    return false;
  }
}

function isItemSize(candidate: any) : candidate is ItemSize {  
  try {
    return (
      typeof candidate.height === 'number' &&
      typeof candidate.width === 'number' 
    );
  }
  catch(error) {
    return false;
  }
}

 function isOidcConfig(candidate: any) : candidate is OidcConfig {  
  try {
    return (
      typeof candidate.authority === 'string' &&
      typeof candidate.clientId === 'string' &&
      typeof candidate.clientSecret === 'string' &&
      typeof candidate.redirectUri === 'string'
    );
  }
  catch(error) {
    return false;
  }
}
