/**
 * Configuration of the 'instances' store in the IndexDB.
 * 
 * This store keeps the ID of the currently active maco execution environment. 
 *    
 * The key is a constant (instanceKey), the only entry is the currently active instance (InstancesEntry interface).
 */

/**
 * The name of the 'instances' store.
 */
export const instancesStoreName = 'instances'

/**
 * The key path of the 'instances' store.
 */
export const instancesKeyPath = 'level';

/**
 * The single key value in the 'instances' store.
 */
export const instancesSingleKeyValue = 'current';

/**
 * The type of objects populating the 'instances' store.
 */
export interface InstancesEntry {
  level: string
  instanceId: string
}

export function isInstancesEntry(candidate: any) : candidate is InstancesEntry {  
  try {
    return (
      typeof candidate.level === 'string' 
      && typeof candidate.instanceId === 'string'
    );
  }
  catch(error) {
    return false;
  }
}
