import { AuthContextProps, User as UserOidc } from "oidc-react";
import { Auth } from "../authmock/Auth";
import { User } from "../authmock/User";

/**
 * The component dealing with authentication for the web service API calls.
 * 
 * We wrap the ee4maco specific requirements in functions around the auth structure
 * returned by the AuthProvider library.
 */
export default class Authenticator {
  private mySignOut : (() => void) | undefined;
  private auth : Auth | AuthContextProps| undefined;


  /**
   * Set the method to call at logout.
   */
  public setSignOut(signOut : () => void) {
    console.log('Setting signOut method in Authenticator');
    this.mySignOut = signOut;
  }

  /**
   * Set the currently valid auth structure.
   */
  public configure(auth : Auth | AuthContextProps) {
    this.auth = auth;
  }

  /**
   * Are we currently authenticated for a user token?
   */
   public isAuthenticated() : boolean {
    return true &&
      this.auth !== undefined &&
      this.auth.userData !== undefined &&
      this.auth.userData !== null &&
      this.auth.userData.profile.studentId !== '' &&
      this.auth.userData.profile.runId !== '' &&
      this.auth.userData.profile.sid !== '' 
      ;
  }

  /**
   * Get the set of ID representing the session from the currently authenticated user token.
   */
  public getSessionId() : SessionId {
    return {
      studentId : this.getStudentId(),
      runId : this.getRunId(),
      sid: this.getSid()
    }
  }

  /**
   * Get the studentId of the currently authenticated user token.
   */
  public getStudentId() : string {
    return this.getNonStandardUserComponent((userData) => userData.profile.studentId);
  }

  /**
   * Get the runId of the currently authenticated user token.
   */
  public getRunId() : string {
    return this.getNonStandardUserComponent((userData) => userData.profile.runId);
  }

  /**
   * Get the sid of the currently authenticated user token.
   */
  public getSid() : string {
    return this.getNonStandardUserComponent((userData) => userData.profile.sid);
  }

  /**
   * Get the authentication token string to use in the request header for server API calls.
   */
    public getAuthenticationTokenServerString() : string {
      return this.getUserStructure().access_token;
    }  
  
  /**
   * Get the value of an optional component of the User structure inside our authentication structure.
   * 
   * We raise an error if the component is not there or empty.
   */
  private getNonStandardUserComponent(accessor : (user : User | UserOidc) => unknown) : string {
    const result = accessor(this.getUserStructure());
    if (result === undefined ||
      result === null ||
      typeof result != 'string' ||
      result === '') {
      throw new Error(`Not authenticated properly!`);
    }
    return result;
  }


    /**
   * Get the value of an standard component of the User structure inside our authentication structure.
   * 
   * We raise an error if the component is not there or empty.
   */
  private getStandardUserComponent(accessor : (user : User | UserOidc) => string | undefined | null) : string {
    const result = accessor(this.getUserStructure());
    if (result === undefined ||
      result === null ||
      result === '') {
      throw new Error(`Not authenticated properly!`);
    }
    return result;
  }

  /**
   * Get the User structure inside our authentication structure. 
   * 
   * We raise an error if the User structure is not there.
   */
  private getUserStructure() : User | UserOidc {
    if (this.auth === undefined) {
      throw new Error(`Authenticator is not configured currently!`);
    }
    if (this.auth.userData === undefined || this.auth.userData === null) {
      throw new Error(`Not authenticated currently!`);
    }
    return this.auth.userData;
  }

  /**
   * Use the specifically configured signOut method to log out. 
   * If no specific method is configured we tell the OpenId Connect mechanism to log out.
   */
  public logout() : void {
    if (this.auth === undefined) {
      throw new Error(`Authenticator is not configured currently!`);
    }
    if (this.mySignOut === undefined) {
      this.auth.signOutRedirect();
    }
    else {
      this.auth.signOut().then(() => this.mySignOut!());
    }
  }
}

/**
 * The combination of IDs that fully identify an assessment session.
 */
export interface SessionId {
  studentId: string,
  runId:string,
  sid: string
};

