/**
 * Configuration of the 'snapshots' store in the IndexDB.
 * 
 * This store keeps the last assessment snapshot collected for each assessmentId. 
 *    
 * The key is the assessmentId, the value is the assessment snapshot.
 */
import { AssessmentSnapshot, isAssessmentSnapshot } from "../utils/ApiCalls";

/**
 * The name of the 'snapshots' store.
 */
export const snapshotsStoreName = 'snapshots'

/**
 * The key path of the 'snapshots' store.
 */
export const snapshotsKeyPath = 'assessmentId';

/**
 * The type of objects populating the snapshots object store.
 */
export interface SnapshotsEntry {
  assessmentId: string,
  data: AssessmentSnapshot
}

export function isSnapshotsEntry(candidate: any) : candidate is SnapshotsEntry {  
  try {
    return (
      typeof candidate.assessmentId === 'string' 
      && isAssessmentSnapshot(candidate.data)
    );
  }
  catch(error) {
    return false;
  }
}
