import { callAddScoring, ScoringResult, TaskIdentification } from "../utils/ApiCalls";
import LocalDatabase from "../database/LocalDatabase";
import Authenticator from "../auth/Authenticator";
import { sendMessageToTaskPlayer } from "./MessageSender";
import MessageReceiver from "./MessageReceiver";
import ProcessingResultsCollector from "../utils/ProcessingResultsCollector";
import { v4 } from "uuid";
import Logger from "../utils/Logger"; 
import { isServerInternalError } from "../utils/TextUtils";

/**
 * The component that manages the scoring result transmissions.
 */
export default class ScoringController {
  private log: Logger;

  // Collects results of snapshot transmissions to the server:
  private serverTransmissionsCollector = new ProcessingResultsCollector('sendScoring');
  // Catalog of pending request IDs:
  private requestsCatalog : RequestsCatalogEntry[] = [];

  constructor(log : Logger) {
    this.log = log;
  }

  /**
   * Set up our listener in the message receiver listening for scoring results sent by the task player.
   * 
   * Our listener adds some meta data to the runtime scoring result and sends it to the server.
   */
  public configure(
    messageReceiver: MessageReceiver, 
    authenticator: Authenticator, 
    database: LocalDatabase, 
    controllerVersion: string, 
    serverUrl: string, 
    additionalQuote: boolean) 
  {
    messageReceiver.setGetOldScoringResultReturnListener((sendingWindow: MessageEventSource, requestId: string | undefined, result: unknown) => {
      // Copy an enriched version of each snapshot to the database:
      // The SoftwareDriven server expects the ScoringResult as JSON but the attribute 'runtimeScoringResult' should be a string containing the serialized JSON-object.
      const scoringResult : ScoringResult = {
        runtimeScoringResult: additionalQuote === true ? JSON.stringify(result) : result,
        creationTime: this.getIsoDateNow(),
        controllerVersion: controllerVersion, 
      };
      // Transfer snapshots with a request ID to the server (we always assign a request ID to our calls):
      if (requestId !== undefined) {
        // Get the task info from the requests catalog:
        const requestCatalogEntry = this.popRequestCatalogEntry(requestId, this.requestsCatalog);
        if (requestCatalogEntry !== undefined) {
          scoringResult.task = requestCatalogEntry.task.task;
          scoringResult.item = requestCatalogEntry.task.itemName;
          scoringResult.scope = requestCatalogEntry.task.scope;
        }
        // The SoftwareDriven server expects the ScoringResult as JSON but the attribute 'runtimeScoringResult' should be a string containing the serialized JSON-object.
        callAddScoring(serverUrl, authenticator.getAuthenticationTokenServerString(), scoringResult, false)
        .then(() => {
          this.serverTransmissionsCollector.reportProcessingResult({ processingId: requestId, success: true }, this.log);
        })
        .catch((error) => {
          this.serverTransmissionsCollector.reportProcessingResult({ processingId: requestId, success: false, message: error.message }, this.log)
          if (isServerInternalError(error.message)) {
            this.log.signalSystemDown();
          }
        })
      }
    })
  }

  private getIsoDateNow() : string {
    function padTwoDigits(n: number) : string {
      return n < 10 ? '0'+ n : '' + n;
    };
    var d = new Date();
    return d.getUTCFullYear()+'-'
         + padTwoDigits(d.getUTCMonth()+1)+'-'
         + padTwoDigits(d.getUTCDate())+'T'
         + padTwoDigits(d.getUTCHours())+':'
         + padTwoDigits(d.getUTCMinutes())+':'
         + padTwoDigits(d.getUTCSeconds())+'Z';
  }

  /**
   * Obtain an entry from the catalog and drop it there.
   */
  private popRequestCatalogEntry(requestId: string, catalog: RequestsCatalogEntry[]) : RequestsCatalogEntry | undefined {
    const requestInfoIndex = catalog.findIndex(entry => entry.requestId === requestId);
    if (requestInfoIndex >= 0) {
      const requestInfo = catalog[requestInfoIndex];
      catalog.splice(requestInfoIndex, 1);
      return requestInfo;
    } else {
      return undefined;
    }

  }


  /**
   * Return a promise that sends the scoring result of the given task
   * to the management server.
   * 
   * The promise writes a warning to the console if the call fails.
   *  
   * The promise never rejects.
   */
  public immediateServerTransmission(targetWindow : MessageEventSource, task : TaskIdentification) : Promise<void> {
    // Trigger a transmission of the scoring result.
    // We set a unique request ID which our listener will use to identify the result of the final  
    // transmission to the management server in our processing results collector.
    const uniqueQueryId = v4();
    this.requestsCatalog.push({requestId: uniqueQueryId, task });
    this.triggerTaskPlayerTransmission(targetWindow, uniqueQueryId, task);

    // We continue without waiting for the triggering promises since the task player will respond
    // asynchronously anyhow. 

    // Return a promise that waits for the result to appear in the processing results list:
    return this.serverTransmissionsCollector.popProcessingResult(uniqueQueryId, 200)
      .then((transmissionResult) => { 
        if (!transmissionResult.success) {
          this.log.error(`Übertragung Teilergebnis ist gescheitert: ${transmissionResult.message}`);
        }
      });
  }

  /**
   * Return a promise that triggers the task player to send the scoring result for the given task.
   * 
   * The promise triggers the task player and resolves immediately. It never rejects.
   */
   private triggerTaskPlayerTransmission(targetWindow : MessageEventSource, requestId : string, task : TaskIdentification ) : Promise<void> {
    sendMessageToTaskPlayer(targetWindow, {eventType: 'getOldScoringResult', requestId: requestId, task: task.task, item: task.itemName, scope: task.scope});
    return Promise.resolve();
  }

}

/**
 * An entry in our catalog of pending request.
 */
interface RequestsCatalogEntry {
  requestId: string,
  task: TaskIdentification
}
