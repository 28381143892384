import { AssessmentSnapshot, callSetSnapshot, TaskIdentification } from "../utils/ApiCalls";
import LocalDatabase from "../database/LocalDatabase";
import Authenticator from "../auth/Authenticator";
import { sendMessageToTaskPlayer } from "./MessageSender";
import MessageReceiver from "./MessageReceiver";
import TaskSequencer from "./TaskSequencer";
import ProcessingResultsCollector from "../utils/ProcessingResultsCollector";
import LoopWithInterjection from "./LoopWithInterjection";
import { v4 } from "uuid";
import Logger from "../utils/Logger";
import { isServerInternalError } from "../utils/TextUtils";

/**
 * The component that manages the assessment snapshot transmissions.
 */
export default class SnapshotsController {
  private log: Logger;

  // Collects results of snapshot transmissions to the server:
  private serverTransmissionsCollector = new ProcessingResultsCollector('sendSnapshot');
  // Runs the loop triggering the task player to send a full tasks state:
  private loop: LoopWithInterjection | undefined;
  private loopInstanceCount : number = 0;
  // Catalog of pending request IDs:
  private requestsCatalog : RequestsCatalogEntry[] = [];

  constructor(log : Logger) {
    this.log = log;
  }

  /**
   * Set up our listener in the message receiver listening for full tasks states sent by the task player.
   * 
   * Our loop triggers the task player to send full tasks states without "request ID". 
   * The "immediate" calls trigger the task player with a unique "request ID".
   * 
   * Our listener adds the task sequencer's state to each arriving full tasks state 
   * and processes the extended structure as assessment snapshot: 
   *  - save the assessment snapshot in the local database
   *  - send assessment snapshots, that carried a "request ID" in the task player call, to the management server and
   *     put the send call result to the transmissions result collector. 
   */
  public configure(
    messageReceiver: MessageReceiver, 
    taskSequencer: TaskSequencer, 
    authenticator: Authenticator, 
    database: LocalDatabase, 
    controllerVersion: string, 
    serverUrl: string,
    additionalQuote: boolean) 
  {
    messageReceiver.setGetTasksStateReturnListener((sendingWindow: MessageEventSource, requestId: string | undefined, userId: string, state: unknown) => {
      // Copy an enriched version of each snapshot to the database:
      const assessmentSnapshot : AssessmentSnapshot = {
        runtimeTasksState: state,
        taskSequencerState: taskSequencer.getInternalState(),
        creationTime: new Date().getTime(),
        controllerVersion: controllerVersion, 
      };
      database.storeSnapshotInDb(userId, assessmentSnapshot);
      // Transfer snapshots with a request ID to the server (i.e. don't transfer the snapshots triggered by our loop):
      if (requestId !== undefined) {
        // Get the task info from the requests catalog:
        const requestCatalogEntry = this.popRequestCatalogEntry(requestId, this.requestsCatalog);
        if (requestCatalogEntry !== undefined) {
          assessmentSnapshot.task = requestCatalogEntry.task.task;
          assessmentSnapshot.item = requestCatalogEntry.task.itemName;
          assessmentSnapshot.scope = requestCatalogEntry.task.scope;
        }
        callSetSnapshot(serverUrl, authenticator.getAuthenticationTokenServerString(), assessmentSnapshot, additionalQuote)
        .then(() => {
          this.serverTransmissionsCollector.reportProcessingResult({ processingId: requestId, success: true }, this.log);
        })
        .catch((error) => {
          this.serverTransmissionsCollector.reportProcessingResult({ processingId: requestId, success: false, message: error.message }, this.log);
          if (isServerInternalError(error.message)) {
            this.log.signalSystemDown();
          }
        })
      }
    })
  }

  /**
   * Start transmission cycle that sends snapshots to the database but not to the server. 
   * 
   * We transmit a first time immediately and continue transmitting with a fixed interval 
   * between the transmissions.
   */
  public startDbTransmissions(targetWindow: MessageEventSource, intervalMillis: number, interjectionMillis: number) : void {
    if (this.loop !== undefined) {
      this.loop.stopLoop();
    }
    // Using no request id will make our listener not send the snapshot to the server:
    this.loop = new LoopWithInterjection(intervalMillis, interjectionMillis, () => this.triggerTaskPlayerTransmission(targetWindow, undefined), `snapshot ${this.loopInstanceCount}`, this.log, false);
    this.loopInstanceCount += 1;
    this.loop.startLoop();
  }

  /**
   * Stop transmission cycle. 
   */
  public stopDbTransmissions() : void {
    if (this.loop !== undefined) {
      this.loop.stopLoop();  
    }
    this.loop = undefined;
  }


  /**
   * Obtain an entry from the catalog and drop it there.
   */
  private popRequestCatalogEntry(requestId: string, catalog: RequestsCatalogEntry[]) : RequestsCatalogEntry | undefined {
    const requestInfoIndex = catalog.findIndex(entry => entry.requestId === requestId);
    if (requestInfoIndex >= 0) {
      const requestInfo = catalog[requestInfoIndex];
      catalog.splice(requestInfoIndex, 1);
      return requestInfo;
    } else {
      return undefined;
    }

  }


  /**
   * Return a promise that sends an assessment snapshot of the current state in the task player 
   * to the management server.
   * 
   * The promise writes a warning to the console if the call fails.
   *  
   * The promise never rejects.
   */
  public immediateServerTransmission(targetWindow : MessageEventSource, task : TaskIdentification) : Promise<void> {
    // Trigger a transmission of the full tasks state.
    // We set a unique request ID which our listener will use to identify the result of the final  
    // transmission to the management server in our processing results collector.
    const uniqueQueryId = v4();
    this.requestsCatalog.push({requestId: uniqueQueryId, task });
    if (this.loop !== undefined) {
      this.loop.interjectAction(() => this.triggerTaskPlayerTransmission(targetWindow, uniqueQueryId));
    } else {
      this.triggerTaskPlayerTransmission(targetWindow, uniqueQueryId);
    }

    // We continue without waiting for the triggering promises since the task player will respond
    // asynchronously anyhow. 

    // Return a promise that waits for the result to appear in the processing results list:
    return this.serverTransmissionsCollector.popProcessingResult(uniqueQueryId, 200)
      .then((transmissionResult) => { 
        if (!transmissionResult.success) {
          this.log.error(`Übertragung Bearbeitungsstand ist gescheitert: ${transmissionResult.message}`);
        }
      });
  }

  /**
   * Return a promise that that triggers the task player to send a full tasks state snapshot.
   * 
   * The promise triggers the task player and resolves immediately. It never rejects.
   */
   private triggerTaskPlayerTransmission(targetWindow : MessageEventSource, requestId : string | undefined) : Promise<void> {
    sendMessageToTaskPlayer(targetWindow, {eventType: 'getTasksState', requestId: requestId});
    return Promise.resolve();
  }

}

/**
 * An entry in our catalog of pending request.
 */
interface RequestsCatalogEntry {
  requestId: string,
  task: TaskIdentification
}
