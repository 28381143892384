import { TraceLogMessage } from "../utils/ApiCalls";
import { isTracesSendingBunchEntry, isTracesSendingFlagEntry, TracesSendingBunchEntry, tracesSendingBunchKeyValue, TracesSendingFlagEntry, tracesSendingFlagKeyValue } from "./TracesSendingStore";
import { isTracesToSendEntry, TracesToSendEntry } from "./TracesToSendStore";
import Logger from '../utils/Logger';

/**
 * Methods working mainly with the 'tracesToSend' and the 'sendingTraces' stores.
 */

/**
 * Store a trace log message for the given userId in the local database.
 */
export function storeTraceLogTransmissionInDb(db: IDBDatabase, traceLogMessage: TraceLogMessage, log: Logger) : void {
  const transaction = db.transaction(['tracesToSend'], 'readwrite');
  transaction.onerror = (event) => {
    log.error(`Store trace log transaction failed: ${transaction.error}`);
  }
  transaction.onabort = (event) => {
    log.error(`Store trace log transaction aborted.`);
  }
  
  const tracesToSendStore = transaction.objectStore('tracesToSend');
  const maxCountRequest = tracesToSendStore.count();
  maxCountRequest.onerror = (event) => {
    log.error(`Could not count existing entries in tracesToSend store: ${maxCountRequest.error}`);
  }
  maxCountRequest.onsuccess = (event) => {
    const nextIndex : number = maxCountRequest.result;
    const newEntry : TracesToSendEntry = { index: nextIndex, message: traceLogMessage };
    log.setTracesToSendPending(true);
    const storeRequest = tracesToSendStore.put(newEntry);
    storeRequest.onerror = (event) => {
      log.error(`Could not put new entry into tracesToSend store: ${storeRequest.error}`);
    }
  }
}  

/**
 * Return a promise that transmits all trace log messages buffered in the DB to the management server. 
 * 
 * The promise resolves successfully in any case: no messages pending, failure in server call, failure in DB transaction.
 */
export function transmitTraceLogMessagesFromDb(db : IDBDatabase,
    sendCall : (messages : TraceLogMessage[]) => Promise<TraceLogMessage[]>, log: Logger) : Promise<void>
{
  // Switching step:
  // The first transaction switches all messages from 'tracesToSend' to 'tracesSending' store.
  // This transaction runs quickly since it does not call the management server.
  // So we keep the 'tracesToSend' locked for a short time only.
  runMessagesSwitchTransaction(db, log);

  // Transmission step:
  // The messages are in the 'tracesSending' store now (if the first transaction succeeded).
  // The first transaction is complete and the 'tracesToSend' store is not locked anymore.
  // Now we run two transactions on the 'tracesToSend' store with the actual send call between them:
  // The starting transaction puts an 'executing' flag in the 'tracesSending' store, 
  // after that the server call is executed
  // and finally the finishing transaction removes the flag again.
  // (The flag will abort the transmission transactions of other instances trying to do things in parallel.)
  // The transmission step might take quite a while. 
  // We run the transmission step even if the switching step failed: 
  // There might be some left-overs in the 'tracesSending' store that we want to send to the server 
  // in any case.
  return sendMessagesWithinLock(db, sendCall, log);
}  

/**
 * Run a transaction that moves the trace log messages in the tracesToSend object store to the tracesSending object store.
 * 
 * We don't put anything to the tracesSendingStore if no trace log messages wait the the tracesToSend store.
 * We do nothing if the lock flag in the tracesSending store signals a currently running transmission.
 */
function runMessagesSwitchTransaction(database : IDBDatabase, log: Logger) : void {
  const transaction = database.transaction(['tracesToSend', 'tracesSending'], 'readwrite');
  transaction.onerror = (event) => {
    log.error(`Switch trace logs for transmission transaction failed: ${transaction.error}`);
  }
  transaction.onabort = (event) => {
    log.error(`Switch trace logs for transmission transaction aborted.`);
  }
  
  const tracesToSendStore = transaction.objectStore('tracesToSend');
  const tracesSendingStore = transaction.objectStore('tracesSending');

  const flagRequest = tracesSendingStore.get(tracesSendingFlagKeyValue);
  flagRequest.onerror = (event) => {
    log.error(`Could not get flag for tracesSending store (switch): ${flagRequest.error}`);
  }
  flagRequest.onsuccess = (event) => {
    const flag : unknown = flagRequest.result;
    // Don't continue if the tracesSending store is in use by a running transmission:
    if (flag === undefined) {
      getAndSwitchMessages(log);
    } else {
      log.log(`Trace logs switch skipped due to blocking flag on tracesSending store.`)
    }
  }

  /**
   * Fetch the messages from the tracesToSend store and use them as input to the switch operation.
   */
  function getAndSwitchMessages(log: Logger) : void {
    // Get all messages in 'tracesToSend' store:
    const allToSendRequest = tracesToSendStore.getAll();
    allToSendRequest.onerror = (event) => {
      log.error(`Could not get entries in tracesToSend store (switch): ${allToSendRequest.error}`);
    }
    allToSendRequest.onsuccess = (event) => {
      const toSendArray : unknown[] = allToSendRequest.result;
      if (! toSendArray.every(isTracesToSendEntry)) {
        log.error('Invalid entries found in tracesToSend store (switch).');
      } else {
        // We have all messages from 'tracesToSend'store. -> Put them in 'tracesSending' store:
        if (toSendArray.length > 0 ) {
          switchMessagesToTracesSendingStore(toSendArray.map(entry => entry.message), log);
        }
      }
    }
  }

  /**
   * Combine the given messages (from the tracesToSend store) with the old messages in the tracesSending store 
   * and continue the switching with the combination.
   */
  function switchMessagesToTracesSendingStore(toSendArray : TraceLogMessage[], log: Logger) : void 
  { 
    const getOldRequest = tracesSendingStore.get(tracesSendingBunchKeyValue);
    getOldRequest.onerror = (event) => {
      log.error(`Could not get waiting traces in tracesSending store: ${getOldRequest.error}`);
    }
    getOldRequest.onsuccess = (event) => {
      var newMessages : TraceLogMessage[];
      const waitingMessages : unknown = getOldRequest.result;
      if (waitingMessages === undefined) {
        newMessages = toSendArray;
      } else {
        if (! isTracesSendingBunchEntry(waitingMessages)) {
          log.error(`Invalid waiting traces found tracesSending store.`);
          newMessages = toSendArray;
        } else {
          newMessages = waitingMessages.messages.concat(toSendArray);
        }
      }
      replaceMessagesInTracesSendingStore(newMessages, log);
    }
  }

  /**
   * Put the combined old and new messages to the tracesSending store and clear the tracesToSend store.
   */
  function replaceMessagesInTracesSendingStore(messages : TraceLogMessage[], log: Logger) {
    const newEntry : TracesSendingBunchEntry = { current: tracesSendingBunchKeyValue, messages: messages };
    const switchRequest = tracesSendingStore.put(newEntry);
    switchRequest.onerror = (event) => {
      log.error(`Could not replace traces in tracesSending store: ${switchRequest.error}`);
    }
    switchRequest.onsuccess = (event) => {
      // Messages are in 'tracesSending' store now. -> Drop them in 'tracesToSend' store:
      log.setTracesSendingEmpty(false);
      clearMessagesInTracesToSendStore(log);
    }
  }

  /**
   * Clear all objects in the given tracesToSend store.
   */
  function clearMessagesInTracesToSendStore(log: Logger) : void {
    const clearRequest = tracesToSendStore.clear();
    log.setTracesToSendPending(false);
    clearRequest.onerror = (event) => {
      log.error(`Could not delete switched entries in tracesToSend store: ${clearRequest.error}`);
      clearRequest.transaction?.abort();
    }
  }
}


/**
 * Return a promise that 
 *  - sets the blocking flag in the tracesSending store, 
 *  - calls the given send operation on the bunch of trace log messages, 
 *  - replaces the sent bunch from the tracesSending store with the remaining messages from the send call,
 *  - and finally unsets the flag again.
 * 
 * We put the current time into the blocking flag. 
 * We do nothing if there is a flag already set by some other transmission and flag is not expired yet (i.e. its time value is not older than 60 seconds).
 * (If the flag is expired we overwrite it with the current time and continue with our transmission.)
 * 
 * We call the send action with the bunch of trace log messages that should be transmitted to the server.
 * Once the send action has finished we remove our flag from the tracesSending store.
 */
function sendMessagesWithinLock(
  database : IDBDatabase, 
  sendCall : (messages : TraceLogMessage[]) => Promise<TraceLogMessage[]>, 
  log: Logger
) : Promise<void>
{  
  return new Promise((resolve, reject) => {

    // Run a transaction to put the lock:
    var myLockTime : number | undefined = undefined;
    var toTransmit : TraceLogMessage[] = [];
    const transaction = database.transaction(['tracesSending'], 'readwrite');
    transaction.onerror = (event) => {
      log.error(`Transmitting trace logs start transaction failed: ${transaction.error}`);
      resolve();
    }
    transaction.onabort = (event) => {
      log.error(`Transmitting trace logs start transaction aborted.`);
      resolve();
    }
    transaction.oncomplete = (event) => {
      if (myLockTime !== undefined) {
        // We have all messages from 'tracesSending' and the lock on the store.
        // -> Send data to server.
        //    In a transaction of its own after the send call do:
        //     - If send call succeeds replace bunch in tracesSending store with remaining messages, otherwise keep the bunch as is.
        //     - Unlock tracesSending store.
        const lockTime = myLockTime;
        if (toTransmit.length > 0) {
          sendCall(toTransmit)
          .then((remainingMessages : TraceLogMessage[]) => 
            runPostSendCallTransaction(tracesSendingStore.transaction.db, lockTime, remainingMessages, log)
            .then(() => resolve())
          )
          .catch(error => {
            log.error(`Übertragung Bearbeitungsfortschritte ist gescheitert. Wir werden es später nochmal versuchen. Grund: ${error.message}`);
            return runPostSendCallTransaction(tracesSendingStore.transaction.db, lockTime, toTransmit, log)
            .then(() => resolve());
          });
        } else {
          runPostSendCallTransaction(tracesSendingStore.transaction.db, lockTime, toTransmit, log)
          .then(()=> {resolve();});
        }
      } else {
        resolve();
      }     
    }

    const tracesSendingStore = transaction.objectStore('tracesSending');
    const flagRequest = tracesSendingStore.get(tracesSendingFlagKeyValue);
    flagRequest.onerror = (event) => {
      log.error(`Could not get flag for tracesSending store (transmit): ${flagRequest.error}`);
    }
    flagRequest.onsuccess = (event) => {
      var isLocked : boolean;
      const flag : unknown = flagRequest.result;
      if (flag === undefined) {
        isLocked = false;
      } else if (! isTracesSendingFlagEntry(flag)) {
        log.error('Invalid flag in tracesSending store (transmit): ', flag);
        isLocked = true;
      } else {
        const lockAgeInMillis = Date.now() - flag.started;
        if (lockAgeInMillis < 60000 ) {
          log.log(`Trace logs transmission postponed due to lock from ${flag.started}, i.e. ${lockAgeInMillis} millis ago.`)
          isLocked = true;
        } else {
          isLocked = false;
        }
      }
      if (!isLocked) {
        putLock();
        getBunch();
      }
    }

    function putLock() : void
    {
      const theLockTime = Date.now();
      const lockEntry : TracesSendingFlagEntry = { current: tracesSendingFlagKeyValue, started: theLockTime }
      const lockRequest = tracesSendingStore.put(lockEntry)
      lockRequest.onsuccess = (event) => {
        myLockTime = theLockTime;
      }
      lockRequest.onerror = (event) => {
        log.error('Could not set flag in tracesSending store: ', lockEntry);
      }
    }

    function getBunch() : void
    {
      const toSendRequest = tracesSendingStore.get(tracesSendingBunchKeyValue);
      toSendRequest.onerror = (event) => {
        log.error(`Could not get traces in tracesSendingStore (transmit): ${toSendRequest.error}`);
      }  
      toSendRequest.onsuccess = (event) => {
        const toSend : unknown = toSendRequest.result;
        if (toSend === undefined) {
          toTransmit = [];
        } else {
          if (! isTracesSendingBunchEntry(toSend)) {
            log.error('Invalid traces found in tracesSending store (transmit)');
          } else {
            toTransmit = toSend.messages;
          }
        }
      }
    }
  })
}


/**
 * Return a promise that - in a transaction of its own after a send transmissions call - performs these steps: 
 *  - Replace the bunch in tracesSending store with the given remaining messages.
 *  - Unlock tracesSending store.
 */
function runPostSendCallTransaction(
  database: IDBDatabase, 
  myLockTime: number, 
  remainingMessages : TraceLogMessage[], 
  log: Logger) : Promise<void>
   
{
  return new Promise((resolve, reject) => {
    const transaction = database.transaction(['tracesSending'], 'readwrite');
    transaction.onerror = (event) => {
      log.error(`Transmitting trace logs post transaction failed: ${transaction.error}`);
      resolve();
    }
    transaction.onabort = (event) => {
      log.warn(`Transmitting trace logs post transaction aborted!`);
      resolve();
    }
    transaction.oncomplete = (event) => {      
      resolve();
    }
  
    const tracesSendingStore = transaction.objectStore('tracesSending');
    
    const flagRequest = tracesSendingStore.get(tracesSendingFlagKeyValue);
    flagRequest.onerror = (event) => {
      log.error(`Could not get flag in tracesSending store (post transmit).`);
    }
    flagRequest.onsuccess = (event) => {
      const flag : unknown = flagRequest.result;
      if (flag === undefined || ! isTracesSendingFlagEntry(flag) || flag.started !== myLockTime) {
        log.warn('Foreign interaction found after send call: ', flag);
      } else {
        clearAndUnlockInSendingTraces(remainingMessages, log);
      }
    }
  

    /**
     * Drop the flag entry and put the given remaining trace messages in the tracesSending store.
     */
    function clearAndUnlockInSendingTraces(messagesToKeep : TraceLogMessage[], log: Logger) : void 
    {      
      const newBunch : TracesSendingBunchEntry = { current: tracesSendingBunchKeyValue, messages: messagesToKeep };
      const setRequest = tracesSendingStore.put(newBunch);
      setRequest.onerror = (event) => {
        log.error(`Could not set remaining traces in tracesSending store (post transmit): ${setRequest.error}`);
      }
      if (messagesToKeep.length === 0) {
        log.setTracesSendingEmpty(true);
      }

      const unlockRequest = tracesSendingStore.delete(tracesSendingFlagKeyValue);
      unlockRequest.onerror = (event) => {
        log.error(`Could not drop flag in tracesSending store (post transmit): ${unlockRequest.error}`);
      }
  
    }

  })
    
}

