import Logger from "./Logger";

/**
 * Collect results of processing steps and provide a method that waits for a specific result to appear.
 */
export default class ProcessingResultsCollector {
  private collectorName : string;
  private collectedResults : ProcessingResult[] = [];

  /**
   * The given collector name is used for error messages only.
   */
  constructor(collectorName: string) {
    this.collectorName = collectorName;
  }

  /**
   * Add a processing result to the list of collected results. 
   * 
   * This will (after a short delay) resolve all promises waiting for a result with the given processing ID.
   * 
   * We write an error message to the console if we already have an
   * entry for the given processing id in our list. 
   */
  public reportProcessingResult(newResult : ProcessingResult, log: Logger) : void {
    if (this.collectedResults.find(result => result.processingId === newResult.processingId) !== undefined) {
      log.error(`Multiple results reported for processing id ${newResult.processingId} in collector ${this.collectorName}`);
    }
    this.collectedResults.push(newResult);
  }
  
  /**
   * Return a promise that checks the collected results repeatedly and 
   * resolves as soon as the result for the given processId is available.
   * 
   * The promise never rejects.
   * 
   * The promise waits the given wait interval before repeating the check. 
   * The promise removes the result from the list of collected results before resolving.
   */
  public popProcessingResult(processingId : string, waitInterval : number) : Promise<ProcessingResult> {
    return new Promise<ProcessingResult>((resolve, reject) => {
      this.searchLoop(processingId, waitInterval, (result) => { this.dropResult(processingId); resolve(result); });
    });
  }

  /**
   * Drop the result for the given processingId from our results list.
   */
  private dropResult(processingId: string) : void {
    const foundIndex = this.collectedResults.findIndex(result => result.processingId === processingId);
    if (foundIndex >= 0) {
      this.collectedResults.splice(foundIndex, 1);
    }
  }

  /**
   * Run a loop that triggers the given foundAction once the result for the given processingId is there.
   * 
   * The loop waits the given interval (in millis) between the checks. 
   */
  private searchLoop(processingId: string, waitInterval: number, foundAction: (result: ProcessingResult) => void) {
    const foundResult = this.collectedResults.find(result => result.processingId = processingId);
    if (foundResult !== undefined) {
      foundAction(foundResult);
    } else {
      window.setTimeout(() => { this.searchLoop(processingId, waitInterval, foundAction)}, waitInterval);
    }
  }

}

export interface ProcessingResult {
  processingId : string, 
  success : boolean,
  message?: string 
}
