/**
 * Helper methods to work with the WebStorage
 */

const webStorageAssessmentIdKey = 'currentAssessmentId';

/**
* Set the assessment id in the session storage area and the local storage area
* of the WebStorage. 
* 
* This will be persisted there until the browser closes.
*/
export function setAssessmentIdInWebStorage(assessmentId : string) : void {
  window.localStorage.setItem(webStorageAssessmentIdKey, assessmentId);
  window.sessionStorage.setItem(webStorageAssessmentIdKey, assessmentId);
}
 
/**
* Drop the assessment id from the session storage area and the local storage area
* of the WebStorage. 
*/
export function dropAssessmentIdInWebStorage() : void {
  window.localStorage.removeItem(webStorageAssessmentIdKey);
  window.sessionStorage.removeItem(webStorageAssessmentIdKey);
}

// TODO: reconsider this for the OpenID Connect mechanism:
/**
* Get the assessment id from the WebStorage.
* 
* The method returns the assessment id if it finds the same assessment id 
* in the session storage and the local storage. 
* Otherwise it returns undefined. 
* 
* Since the session storage is specific to a single tab 
* and gets cleared once the tab closes
* and the local storage is specific to our URL origin, 
* the method will return an assessment id only if
*  - the current tab had a session running for that assessment id
*  - no other tab in the browser started a session for another assessment id.
* 
* With both conditions satisfied the current tab could safely
* resume a session with the returned assessment id. 
* 
* Otherwise we would take some risk without a new login dialog:
* 
* Ids differ or no id in local storage:
* If the assessment ids differ, another tab opened a session for another 
* assessment since we used our tab the last time. If there is no id in the local storage,
* another tab closed a session. We should force a new login dialog in both cases
* to make the user aware that the session changes done by the other tab
* apply to our tab as well. Otherwise the user might believe 
* she could run different sessions in different tabs concurrently. 
* (Having different sessions in different tabs makes things confusing. 
* We want to avoid that.)
* 
* No Id in session storage:
* If there is no assessment id in the session storage the 
* assessment id in the local storage might be quite old. 
* We should force a new login to make sure we don't 
* reopen a session opened by somebody else a long time ago.
*
*/
export function getAssessmentIdFromWebStorage() : string | undefined {
  const sessionResult : string | null = window.sessionStorage.getItem(webStorageAssessmentIdKey);
  const localResult : string | null = window.localStorage.getItem(webStorageAssessmentIdKey);
  return sessionResult === null || localResult === null || sessionResult !== localResult ? undefined : sessionResult;
}




