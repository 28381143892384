/**
 * Configuration of the 'tracesToSend' store in the IndexDB.
 * 
 * This store keeps the list of trace log messags to send to the server. 
 *    
 * The key is an index of the messages.
 */
import { isTraceLogMessage, TraceLogMessage } from "../utils/ApiCalls";

/**
 * The name of the 'tracesToSend' store.
 */
export const tracesToSendStoreName = 'tracesToSend'

/**
 * The key path of the 'tracesToSend' store.
 */
export const tracesToSendKeyPath = 'index';

/**
 * The type of objects populating the 'tracesToSend' object store.
 */
export interface TracesToSendEntry {
  index: number,
  message: TraceLogMessage
}

export function isTracesToSendEntry(candidate: any) : candidate is TracesToSendEntry {  
  try {
    return (
      typeof candidate.index === 'number' 
      && isTraceLogMessage(candidate.message)
    );
  }
  catch(error) {
    return false;
  }
}
