import React from "react";
import { Auth } from "./Auth";
import BooleanInput from "./BooleanInput";
import CommandButton from "../gui/CommandButton";
import TextInput from "../gui/TextInput";

/**
 * The area with controls that define the status of our AuthProvider mock implementation.
 */
export function MockConfiguration(props: { authUpdater: (auth: Auth) => void } ) : JSX.Element 
{
  
  const isAuthenticatedAccess = React.useState(false);
  const studentIdAccess = React.useState('CKI');
  const runIdAccess = React.useState('runA');
  const sidAccess = React.useState('sidA');
  const isLoadingAccess = React.useState(false);

  return (
    <div className="mockcontrols">
      Auth'd: <BooleanInput controlId='authenticatedInput' stateAccess={isAuthenticatedAccess}/>
      StudId: <TextInput controlId='navigatorInput' stateAccess={studentIdAccess} />
      RunId: <TextInput controlId='navigatorInput' stateAccess={runIdAccess} />
      sid: <TextInput controlId='navigatorInput' stateAccess={sidAccess} />
      Loading : <BooleanInput controlId='authenticatedInput' stateAccess={isLoadingAccess}/>
      <div>
        <CommandButton controlId='updateButton' label='Update Auth' children={[]} style={undefined} handler={() => props.authUpdater(buildAuth())}/>          
      </div>
    </div>
  );

  /**
   * Build an Auth structure with values from the input controls.
   */
  function buildAuth() : Auth {
    return {
      userData: isAuthenticatedAccess[0] ? { access_token: `Token_${studentIdAccess[0]}_${runIdAccess[0]}_${sidAccess[0]}_}`, profile: { 
        studentId : studentIdAccess[0],
        runId : runIdAccess[0],
        sid: sidAccess[0]
      }} : undefined,
      isLoading: isLoadingAccess[0],
      signOut: () => new Promise((resolve) => alert(`Called signout`)),
      signOutRedirect: () => new Promise((resolve) => alert(`Called signoutRedirect`))
    }
  }

}



