import React from 'react';
import { AuthContextProps, useAuth } from 'oidc-react';
import { ItemSize } from '../utils/FileDownload';
import LocalDatabase from '../database/LocalDatabase';
import BufferedTransmitter from '../controller/BufferedTransmitter';
import SnapshotsController from '../controller/SnapshotsController';
import Authenticator from './Authenticator';
import { LoginLoop } from './LoginLoop';


/**
 * Wrap the LoginLoop in a layer that initializes the Authenticator 
 * with the authentication context that the oidc library provides.
 */
export function AuthWrapper(props: {
  itemSize: ItemSize, 
  localDatabase: LocalDatabase, 
  bufferedTransmitter: BufferedTransmitter, 
  snapshotsController: SnapshotsController, 
  serverLoginUrl: string,
  authenticator: Authenticator
}) : JSX.Element 
{
  const auth : AuthContextProps = useAuth();
  props.authenticator.configure(auth);
  return (
    <LoginLoop 
      itemSize={props.itemSize} 
      localDatabase={props.localDatabase} 
      bufferedTransmitter={props.bufferedTransmitter} 
      snapshotsController={props.snapshotsController} 
      serverLoginUrl={props.serverLoginUrl} 
      auth= {auth}
      signOut= {() => props.authenticator.logout()} 
    />
  )
}
