/**
 * Send a message to the TaskPlayer running in the given target window.
 * 
 * To reduce the effort required at updates of the TaskPlayer-API this method implements only those messages that we actually use.
 */
export function sendMessageToTaskPlayer(targetWindow : MessageEventSource, payload : 
    // configuration control
    { eventType: 'setWaitMessages', primary: string, secondary: string} | 
    { eventType: 'addItem', itemConfig: object, resourcePath: string, externalResourcePath: string, libraryPathsMap: {MathJax: string} } |
    { eventType: 'setScalingConfiguration', scalingMode: string, alignmentHorizontal: string, alignmentVertical: string} |
    { eventType: 'clearItems'} |
    // trace control
    { eventType: 'insertMessageInTrace', message: string} |
    { eventType: 'flushTrace'} |
    { eventType: 'setTraceLogTransmissionChannel', channel: 'postMessage', targetWindowType: 'parent' | 'self' | 'opener', targetOrigin: string, interval: number} |
    { eventType: 'setTraceContextId', contextId: string} |
    // The setTraceContentFilter event is experimental and might change with the next release:
    { eventType: 'setTraceContentFilter', replaySupport: boolean, withExtendedMetaData: boolean, withoutItemConfiguration: boolean, withoutSnapshot: boolean, withoutScoring: boolean} |
    // recordings control 
    // user control
    { eventType: 'setUserId', id: string} |
    { eventType: 'logout'} |
    // task control
    { eventType: 'startTask', scope: string, item: string, task: string} |
    { eventType: 'stopTask'} |
    { eventType: 'setTaskSequencer', targetWindowType: 'parent', targetOrigin: string} |
    // task state control
    { eventType: 'getTasksState', requestId?: string} |
    { eventType: 'clearTasksState' } |
    { eventType: 'preloadTasksState', state: unknown } |
    // scoring control
    { eventType: 'getOldScoringResult', requestId?: string, scope: string, item: string, task: string} 
    // state machine control
    // header control
    // developer mode control
  ) 
{
  targetWindow.postMessage(JSON.stringify(payload), { targetOrigin: '*'});    
}


export interface HeaderButtonDescription {
  image?: string, 
  text: string,
  event: string,
  height: number,
  width: number
}

export interface HeaderMenuScopeDescription {
  name: string, 
  tasks: {item: string, task: string}[]
}

