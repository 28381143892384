import React from 'react';

/**
 * A button used to trigger a command.
 */
export default function CommandButton(props: {controlId: string, label: string | undefined, children : JSX.Element[] | JSX.Element, style: React.CSSProperties | undefined, handler: () => void}) : JSX.Element {
  return <button 
            data-cy={props.controlId}
            className='CommandButton'
            type='button'
            onClick={props.handler}
            style={props.style}
          >
            {props.children}
            {props.label === undefined ? null : props.label}
          </button>
  
}


