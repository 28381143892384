import { isTraceLogMessage, TraceLogMessage } from "../utils/ApiCalls";
import Logger from '../utils/Logger';


/**
 * Service that receives messages from the task player
 * and delivers them to the configured listeners. 
 * 
 * All listeners are called with the frame window of the sending task player
 * (besides the specific message content).
 */
export default class MessageReceiver {
  private log: Logger;
  
  private playerReadyListener : ((source: MessageEventSource) => void) | 'noListener' = 'noListener'; 
  private loginDialogClosedListener : ((source: MessageEventSource, requestId: string | undefined, result : string) => void) | 'noListener' = 'noListener';   
  private traceLogListener : ((source: MessageEventSource, traceLogTransmission: TraceLogMessage) => void) | 'noListener' = 'noListener'; 
  private getOldScoringResultReturnListener : ((source: MessageEventSource, requestId: string | undefined, result: unknown) => void) | 'noListener' = 'noListener'; 
  private getTasksStateReturnListener : ((source: MessageEventSource, requestId: string | undefined, userId: string, state: unknown) => void) | 'noListener' = 'noListener'; 
  private taskSwitchRequestListener : ((source: MessageEventSource, request: RequestType, requestDetails?: TaskRequestDetails) => void) | 'noListener' = 'noListener'; 

  constructor(log : Logger) {
    this.log = log;
  }

  /**
   * Start to receive messages.
   */
  public startReceiving() {
    window.addEventListener("message", (event) => {
      this.processMessageEvent(event);
    })
  }
  
  public setPlayerReadyListener(listener: (source: MessageEventSource) => void) : void {
    this.playerReadyListener = listener;
  }

  public setLoginDialogClosedListener(listener: (source: MessageEventSource, requestId: string | undefined, result : string) => void) : void {
    this.loginDialogClosedListener = listener;
  }

  public setTraceLogListener(listener: (source: MessageEventSource, traceLogTransmission: TraceLogMessage) => void) : void {
    this.traceLogListener = listener;
  }

  public setGetOldScoringResultReturnListener(listener: (source: MessageEventSource, requestId: string | undefined, result: unknown) => void) : void {
    this.getOldScoringResultReturnListener = listener;
  }


  public setGetTasksStateReturnListener(listener: (source: MessageEventSource, requestId: string | undefined, userId: string, state: unknown) => void) : void {
    this.getTasksStateReturnListener = listener;
  }

  public setTaskSwitchRequestListener(listener: (source: MessageEventSource, request: RequestType, requestDetails?: TaskRequestDetails) => void) : void {
    this.taskSwitchRequestListener = listener;
  }

  private processMessageEvent(event : MessageEvent<any>) : void {
    const { origin, data, source } = event;

    if (source === null) {
      this.log.log(`Ignoring message without source.`);
      return;
    }

    if (origin !== window.origin) {
      this.log.log(`Ignoring message from wrong origin. Message origin is ${origin}. Accepted origin is ${window.origin}.`);
      return;
    }

    let dataObject;
    try {
      dataObject = JSON.parse(data);
    } catch (e) {
      this.log.log(`Ignoring message with non-JSON data: ${data}`);
      return;
    }

    const eventType = dataObject.eventType;

    if (eventType === 'taskPlayerReady' && this.playerReadyListener !== 'noListener') {
      this.playerReadyListener(source);
      return;
    }

    if (eventType === 'loginDialogClosed' && this.loginDialogClosedListener !== 'noListener') {
      const requestId : unknown = dataObject.requestId;
      const fieldValue : unknown = dataObject.fieldValue;
      if ((requestId === undefined || typeof(requestId) === 'string') && typeof fieldValue === 'string') {
        this.loginDialogClosedListener(source, requestId, fieldValue);
      } else {
        this.log.error(`Ignored invalid login dialog closed response:  request id: ${requestId}, field value: ${fieldValue}`);
      }
      return;
    }

    if (eventType === 'traceLogTransmission' && this.traceLogListener !== 'noListener') {
      const logData : unknown = dataObject.traceLogData;
      if (isTraceLogMessage(logData)) {
        this.traceLogListener(source, logData);
      } else {
        this.log.error(`Ignored invalid trace log transmission!`, logData);
      }
      return;
    }

    if (eventType === 'getOldScoringResultReturn' && this.getOldScoringResultReturnListener !== 'noListener') {
      const requestId : unknown = dataObject.requestId;
      const result : unknown = dataObject.result;
      if ((requestId === undefined || typeof(requestId) === 'string')) 
      {
        this.getOldScoringResultReturnListener(source, requestId, result);
      } else {
        this.log.error(`Ignored invalid scoring response: request id: ${requestId}`, result);
      } 
      return;
    }

    if (eventType === 'getTasksStateReturn' && this.getTasksStateReturnListener !== 'noListener') {
      const requestId : unknown = dataObject.requestId;
      const userId : unknown = dataObject.userId;
      const state : unknown = dataObject.state;
      if ((requestId === undefined || typeof(requestId) === 'string') && typeof(userId) === 'string') 
      {
        this.getTasksStateReturnListener(source, requestId, userId, state);
      } else {
        this.log.error(`Ignored invalid assessment snapshot response: request id: ${requestId}, userId: ${userId}`, state);
      } 
      return;
    }

    if (eventType === 'taskSwitchRequest' && this.taskSwitchRequestListener !== 'noListener') {
      const request : unknown = dataObject.request;
      const scope : unknown = dataObject.scope;
      const item : unknown = dataObject.item;
      const task : unknown = dataObject.task;
      const requestDetails : unknown = {scope, item, task};
      if (isRequestType(request) && request !== 'goToTask') {
        this.taskSwitchRequestListener(source, request, undefined);
      } else if (isRequestType(request) && request === 'goToTask' && isTaskRequestDetails(requestDetails)) {
        this.taskSwitchRequestListener(source, request, requestDetails);
      } else {
        this.log.error(`Ignored invalid task switch request: request type: ${request}, scope: ${scope}, item: ${item}, task: ${task}`);
      }

      return;
    }
  }
}



/**
 * The type of a task switch request sent by the task player.
 */
export type RequestType = 'nextTask' | 'previousTask' | 'cancelTask' | 'goToTask';

function isRequestType(candidate: any) : candidate is RequestType {
  return candidate === 'nextTask' || candidate === 'previousTask' || candidate ===  'cancelTask' || candidate ===  'goToTask';
}

/**
 * The details of an explicit task switch request (i.e. of goToTask type) sent by the task player.
 */
export interface TaskRequestDetails {
  item?: string, 
  task: string, 
  scope: string
}

function isTaskRequestDetails(candidate: any) : candidate is TaskRequestDetails {
  try {
    return (
        (candidate.item === undefined || typeof candidate.item === 'string')
      && typeof candidate.task === 'string'
      && typeof candidate.scope === 'string'
    )
  } 
  catch(error) {
    return false;
  }

}
