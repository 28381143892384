/**
 * Helper methods providing commonly used text snippets.
 */

// ----------------- public API --------------------------------------------------------------

/**
 * Get the main message in case of a major server system problem.
 */
export function getMessageSystemDownMain() : string {
  return 'Der MSK-OC steht im Augenblick aus technischen Gründen nicht zur Verfügung.';
}

/**
 * Get the secondary message in case of a major server system problem.
 */
export function getMessageSystemDownSecondary(withRetry : boolean) : string {
  return `Bitte sag deiner Lehrkraft Bescheid${withRetry ? ', wenn diese Fehlermeldung auch bei "Neuer Versuch" nicht verschwindet': ''}. Sie kann diesen Fehler an msk-support@dzlm.de melden.`;
}

/**
 * Determine whether the given message signals an internal server error.
 */
export function isServerInternalError(message : string ) : boolean {
  return message.endsWith(getServerInternalErrorPostfix());
}

/**
 * Optionally mark the given message as signalling an internal server error.
 */
export function markServerInternalError(message : string, doMark : boolean) : string {
  return doMark ? `${message} ${getServerInternalErrorPostfix()}` : message;
}

function getServerInternalErrorPostfix() : string {
  return 'server-internal-error';
}
