import LocalDatabase from "../database/LocalDatabase";
import MessageReceiver from "./MessageReceiver";
import ProcessingResultsCollector from "../utils/ProcessingResultsCollector";
import { v4 } from "uuid";
import BufferedTransmitter from "./BufferedTransmitter";
import { TraceLogEntry, TraceLogMessage } from "../utils/ApiCalls";
import { sendMessageToTaskPlayer } from "./MessageSender";
import Logger from "../utils/Logger";

/**
 * The component that manages the processing of trace log messages coming in from the task player.
 */
export default class TraceLogsController {
  private log: Logger;

  // Collects results of trace log transmissions to the server:
  private serverTransmissionsCollector = new ProcessingResultsCollector('sendTraceLogs');

  constructor(log : Logger) {
    this.log = log;
  }

  /**
   * Set up our listener for trace log messages sent by the task player.
   * 
   * Our listener stores the arriving trace log messages to the local database.
   * If a trace log message contains an "end-of-assessment" marker it flushes the trace log buffer
   * in the local database and puts the result of the flush operation into our transmission results collector.
   * 
   */
  public configure(
    messageReceiver: MessageReceiver, 
    bufferedTransmitter: BufferedTransmitter,
    database: LocalDatabase) 
  {
    messageReceiver.setTraceLogListener((sendingWindow: MessageEventSource, traceLogMessage: TraceLogMessage) => {
      // Copy the trace log message to the database. The BufferedTransmitter will send it from there to the server.
      database.storeTraceLogMessageInDb(traceLogMessage);
      // If the trace logs message contains a marker for the end of an assessment session 
      // force an immediate upload to the server and put the transmission result in our result collector.
      const markerId = getIdFromFirstAssessmentEndMarker(traceLogMessage);
      if (markerId !== undefined) {
        bufferedTransmitter.flush().then(() => {this.serverTransmissionsCollector.reportProcessingResult({ processingId: markerId, success: true}, this.log);});
      }
    })
  }

  /**
   * Return a promise that puts an end-of-session marker into the trace log and
   * immediately uploads the trace log to the server. 
   * 
   * The promise writes a warning to the console if the transmission fails. 
   * 
   * The promise never rejects.
   */
  public doImmediateEndOfSessionTransmission(targetWindow : MessageEventSource) : Promise<void> {
    // We write a unique ID together with the "end-of-session" marker into the trace log and trigger the task player
    // to flush the trace logs. 
    // Our listener scans for those markers in the arriving trace log messages and triggers a database buffer flush if a marker appears. 
    // The listener puts the result of the buffer flush operation
    // in our processing results collector using the unique ID in the marker as result identifier.
    const uniqueMarkerId = v4();
    delimitLogTransmission(targetWindow, buildAssessmentEndMarker(uniqueMarkerId));
    return this.serverTransmissionsCollector.popProcessingResult(uniqueMarkerId, 200)
      .then((transmissionResult) => {
        if (!transmissionResult.success) {
          this.log.error(`Übertragung Bearbeitungsfortschritte ist gescheitert. Bitte warte ein paar Sekunden, damit wir es nochmal versuchen können. Grund: ${transmissionResult.message}`);
        }
      });    
  }


}

/**
 * Force the CBA runtime to emit a trace log message that contains a single 'end-of-task' marker entry.
 * 
 * This will work reliably only if no task is running currently!
 */
export function delimitTaskInTraceLogs(targetWindow: MessageEventSource) : void {
  delimitLogTransmission(targetWindow, taskEndMarker);
}

/**
 * Force the CBA runtime to emit a trace log message that contains a single 'marker' trace log entry.
 * 
 * This will work reliably only if no task is running currently!
 */
function delimitLogTransmission(targetWindow: MessageEventSource, markerMessage: string) {
  flushTrace(targetWindow);
  putMessageToTrace(targetWindow, markerMessage);
  flushTrace(targetWindow);
}

/**
 * Put a message to the trace in the CBA runtime.
 */
 function putMessageToTrace(targetWindow: MessageEventSource, message: string) : void {
  sendMessageToTaskPlayer(targetWindow, {eventType: 'insertMessageInTrace', message: message });
}

/**
 * Flush the trace log in the CBA runtime.
 */
function flushTrace(targetWindow: MessageEventSource) : void {
  sendMessageToTaskPlayer(targetWindow, {eventType: 'flushTrace'});
}


/**
 * Return the ID of the first marker entry in the given trace log message that matches the given marker message. 
 * 
 * We return 'udnefined' if there is no marker entry matching the given marker message.
 * 
 * We export this for testing only.
 */
export function getIdFromFirstAssessmentEndMarker(transmission : TraceLogMessage) : string | undefined {
  return transmission.logEntriesList
    .map(entry => getIdFromAssessmentEndMarkerEntry(entry))
    .find(id => id !== undefined);    
}

/**
 * Read the ID follwing the given marker message in the given trace log entry.
 * 
 * We return 'undefined' if the trace log entry does not contain a marker message at all or 
 * if its marker message does not match the given marker message. 
 */
function getIdFromAssessmentEndMarkerEntry(entry : TraceLogEntry) : string | undefined {
  if (entry.type !== 'RuntimeController') {
    return undefined;
  }

  const details = entry.details as {
    details: string
  };
  return details.details.startsWith(assessmentEndMarkerPrefix) 
    ? details.details.substring(assessmentEndMarkerPrefix.length) 
    : undefined;
}

/**
 * Get the marker string used to singal the end of the trace log for a user session.
 */
function buildAssessmentEndMarker(markerId: string) : string {
  return `${assessmentEndMarkerPrefix}${markerId}`;
}

const assessmentEndMarkerPrefix = 'End of log for user session reached. Internal marker ID: ';
const taskEndMarker = 'End of log for task reached.'




