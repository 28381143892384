/**
 * Central logger component that helps implementing our error management concept.
 */

import { createContext } from "react";

// ----------------- public API --------------------------------------------------------------

export default interface Logger {

  /**
   * Log some non-important informational message.
   * 
   * We just write the message to the browser console.
   */
  log(message: string, object?: any) : void;

  /**
   * Log some important informational message.
   * 
   * We just write the message to the browser console.
  */
  info(message: string, object?: any) : void;

  /**
   * Log a warning message: Strange but not an error, might be a signal for some problem.
   *
   * We just write the message to the browser console.
   */
  warn(message: string, object?: any) : void;

  /**
   * Log an error message: Some error occured. This needs to be dealt with.
   * 
   * We write the message to the browser console and put it to the messages list in the technical support area.
   */
  error(message: string, object?: any) : void;

  /**
   * Get the messages that we collected (as plain strings).
   */
  getCollectedMessages(): string[];

  /**
   * Manage a flags for pending trace transmissions.
   */
  setTracesToSendPending(value: boolean) : void;
  setTracesSendingEmpty(value: boolean) : void;  
  getTracesUploadPending() : boolean;

  /**
   * Set a callback that we will trigger each time a collected messages or one of the traces send flags changes.
   * 
   * We support one callback only. Clients of this method should cooperate!
   */
  setChangesCallback(callback: () => void) : void;

  /**
   * Signal that the server system is down (internal server error was reported).
   */
  signalSystemDown() : void;

  /**
   * Set a callback that we will trigger each time a system-down signal arrives.
   * 
   * We support one callback only. Clients of this method should cooperate!
   */
  setSystemDownCallback(callback: () => void) : void;
}

const defaultLogger : Logger = {
  log: (message, object) => console.log(message, object),
  info: (message, object) => console.info(message, object),
  warn: (message, object) => console.warn(message, object),
  error: (message, object) => console.error(message, object),
  getCollectedMessages: () => [],
  setTracesToSendPending: (value) => {},
  setTracesSendingEmpty: (value) => {},
  getTracesUploadPending: () => false,
  setChangesCallback: (callback) => {},
  signalSystemDown: () => {},
  setSystemDownCallback: () => {}
}
export const LoggerContext = createContext(defaultLogger);


