import React from 'react';

/**
 * A text input field to gather a boolean parameter.
 */
export default function BooleanInput(props: {controlId: string, stateAccess: [boolean, (value: boolean) => void]}) : JSX.Element {
  const [value, setter] = props.stateAccess;
  return <input 
            data-cy={props.controlId}
            className='BooleanInput'
            type='checkbox'
            checked={value}
            onChange={event => setter(event.target.checked)}
  />
}


