import { callAddStudentTraceData, callSupplementStudentTraceData, TraceLogMessage } from "../utils/ApiCalls";
import LocalDatabase from "../database/LocalDatabase";
import Authenticator, { SessionId } from "../auth/Authenticator";
import LoopWithInterjection from "./LoopWithInterjection";
import Logger from "../utils/Logger";
import { isServerInternalError } from "../utils/TextUtils";

/**
 * The component sending the buffered trace log messages to the management server.
 */
export default class BufferedTransmitter {
  private log: Logger;
  private authenticator : Authenticator;
  private serverUrl : string;
  private additionalQuote: boolean;
  private database : LocalDatabase;
  private loop: LoopWithInterjection;

  constructor(authenticator : Authenticator, serverUrl: string, additionalQuote: boolean, database : LocalDatabase, log: Logger) {
    this.log = log;
    this.authenticator = authenticator;
    this.serverUrl = serverUrl;
    this.additionalQuote = additionalQuote;
    this.database = database;
    this.doSingleTransmission = this.doSingleTransmission.bind(this);
    this.loop = new LoopWithInterjection(10000, 300, () => this.doSingleTransmission(), `tracelog`, log, false);
  }

  /**
   * Start transmission cycle. 
   * 
   * We do a first transmission immediately and continue further 
   * transmissions with a fixed interval between the transmissions.
   * 
   * The loop will continue even if the transmission in a loop cycle fails. In that case 
   * the next loop cycle will retry the failed transmission.
   */
  public startTransmissions() : void {
    this.loop.startLoop();
  }

  /**
   * Stop transmission cycle. 
   */
  public stopTransmissions() : void {
    this.loop.stopLoop();  
  }

  /**
   * Return a promise that transmits all buffered trace log messages without further delay and resolves once the transmission is finished. 
   * 
   * The promise resolves successfully in any case: transmission ok, transmission failed, no transmission without authentication.
   * 
   * If the transmission cycle is currently running we "interject" the flush, i.e. 
   *  - we wait for any loop cycle action to finish, 
   *  - do our transmission while blocking further loop cycle actions and 
   *  - release the lock once we are done.
   */
  public flush() : Promise<void> {
    return this.loop.interjectAction(() => this.doSingleTransmission());
  }

  /**
   * Return a promise that transmits the currently available trace log messages in the database
   * to the management server.
   * 
   * The transmission bundles the trace log messages in consecutive bunches with messages belonging to the same studentId/runId/sid and
   * issues a send call for each bunch. This makes sure that each send call contains trace log messages for a single studentId/runId/sid combination only.
   * 
   * The method does not attempt a server call if we don't have an authentication token currently.
   *  
   * The promise resolves successfully in any case: transmission ok, transmission failed, no transmission without authentication.
   * It keeps messages not transmitted due to failed send calls in the local database.
   */
  private doSingleTransmission() : Promise<void> {
    if (this.authenticator.isAuthenticated()) {
      const authToken = this.authenticator.getAuthenticationTokenServerString();
      const sessionIdInToken = this.authenticator.getSessionId();
      return this.database.transmitTraceLogMessagesFromDb((messages) => doSendCalls(messages, this.serverUrl, authToken, sessionIdInToken, this.additionalQuote, this.log));
    } else {
      return Promise.resolve();
    }
  }

}

/**
 * Return a promise that groups the given trace log messages by their studentId/runId/sid, executes a send call for each group and 
 * returns all trace log messages not transmitted due to failed calls. 
 */
function doSendCalls(messages: TraceLogMessage[], serverUrl: string, authToken: string, sessionIdInToken: SessionId, additionalQuotes: boolean, log: Logger) : Promise<TraceLogMessage[]> {
  const requiredCalls = groupBySessionId(messages);
  const requiredPromises = requiredCalls.map(callParameter => doSingleServerCall(callParameter.sessionId, callParameter.traceLogMessages, serverUrl, authToken, sessionIdInToken, additionalQuotes, log));
  return Promise.allSettled(requiredPromises).then((results : PromiseSettledResult<void>[]) => {
    // Each server call just returns "ok" (='resolved') or "failed" (='rejected').
    // We collect all failed messages by grabbing all messages from the call parameters of the "failed" calls:
    return results.flatMap((value, index) => value.status === 'rejected' ? requiredCalls[index].traceLogMessages : []);
  })
}


/**
 * Determine which server call to use and return the Promise doing the call.
 * 
 * If the trace log data does not belong to the currently logged-in studentId/runId/sid we use the server call with an explicit studentId/runId/sid besides the authentication token.
 */
function doSingleServerCall(sessionIdInMessages: string | undefined, messages: TraceLogMessage[], serverUrl: string, authToken: string, sessionIdInToken: SessionId, additionalQuotes: boolean, log: Logger) : Promise<void> {
  return sessionIdInMessages === undefined || sessionIdInMessages === buildSessionIdString(sessionIdInToken)
    ? callAddStudentTraceData(serverUrl, authToken, messages, additionalQuotes)
      .catch((error) => { 
        log.error(`Übertragung Bearbeitungsfortschritt gescheitert. Wir versuchen es später nochmal. Grund: ${error.message}`);
        if (isServerInternalError(error.message)) {
          log.signalSystemDown();
        }
        throw error;}) 
    : callSupplementStudentTraceData(serverUrl, authToken, toSessionId(sessionIdInMessages), messages, additionalQuotes)
      .catch((error) => { 
        log.error(`Übertragung alter Bearbeitungsfortschritt gescheitert. Wir versuchen es später nochmal. Grund: ${error.message}`); 
        if (isServerInternalError(error.message)) {
          log.signalSystemDown();
        }
        throw error;});
}

/**
 * Group the given trace log messages by their session ids into transmissions described by their parameters.
 * 
 * (We export this for testing only.)
 */
export function groupBySessionId(all : TraceLogMessage[]) : TransmissionParameters[] {
  const result : TransmissionParameters[] = [];
  if (all.length === 0) {
    return result;
  }

  var currentSessionId = all[0].metaData.sessionId;
  var currentGroup : TraceLogMessage[] = [];
  currentGroup.push(all[0]);

  var messageIndex;
  for (messageIndex = 1; messageIndex < all.length; messageIndex += 1) {
    const candidate = all[messageIndex];
    if (candidate.metaData.sessionId === currentSessionId) {
      currentGroup.push(candidate);
    } else {
      result.push({sessionId: currentSessionId, traceLogMessages: currentGroup});
      currentSessionId = candidate.metaData.sessionId;
      currentGroup = [];
      currentGroup.push(candidate);
    }
  } 
  
  result.push({sessionId: currentSessionId, traceLogMessages: currentGroup});

  return result;
}

/**
 * The parameters for a single transmission to the management server.
 * 
 * Each transmission sends a bunch of trace log messages having the same session id (aka "trace log context id").
 */
interface TransmissionParameters {
  sessionId : string | undefined, 
  traceLogMessages : TraceLogMessage[]
}

/**
 * Build a string by representation of a SessionId. 
 */
export function buildSessionIdString(sessionId : SessionId) : string {
  return `${sessionId.studentId}_${sessionId.runId}_${sessionId.sid}`;
}

/**
 * Parse a trace context id string created by buildSessionIdString back into a SessionId structure.
 */
export function toSessionId(sessionIdString : string) : SessionId {
  const partsArray = sessionIdString.split("_");
  if (partsArray.length !== 3 ) {
    throw new Error("Invalid trace context id.");
  }
  return {
    studentId : partsArray[0],
    runId: partsArray[1],
    sid: partsArray[2]
  }
}