import React, { useContext } from "react";
import CommandButton from "../gui/CommandButton";
import TextInput from "../gui/TextInput";
import { LoggerContext } from "../utils/Logger";

/**
 * Show the maintenance area with a button to log out and the list of error messages.
 */
export function MaintenanceArea(props: {signOut: () => void, hide: () => void}) : JSX.Element 
{
  const log = useContext(LoggerContext);

  const logoutPass = React.useState('');
  const [getUpdateCount, setUpdateCount] = React.useState(0);

  // Make the logger trigger our refresh by changing our update count:
  log.setChangesCallback(() => setUpdateCount(getUpdateCount + 1));

  const dummyMessages = log.getCollectedMessages();
  // Using the index as key is fine here since list grows at the end only and already existing entries never change:
  const errorMessages = dummyMessages.map((message,index) => createMessageEntry(message, index.toString(10))).reverse();


  const buttonStyleHide = {backgroundColor: 'rgb(44,77,255)', color: 'rgb(255,255,255)', borderColor: 'black', borderStyle: 'solid'};
  const buttonStyle = {backgroundColor: 'rgb(44,77,255)', color: 'rgb(255,255,255)', borderColor: 'black', borderStyle: 'solid', borderRadius: '6px'};

  return (
    <div className='maintenanceArea'>
      {/* first line */}
      <div>        
      </div>
      <div style={{margin: '10px'}}>
        Logout:
      </div>
      <div style={{margin: '10px'}}>
        Hier kannst Du dich abmelden, ohne die Aufgaben zu beenden. Gib hierzu das Kennwort ein, das Du von Deiner Lehrkraft erhalten hast:&nbsp; 
        <TextInput controlId='logoutPassInput' stateAccess={logoutPass} width={80}></TextInput>
        &nbsp;
        <CommandButton controlId='logoutButton' label='Logout' children={[]} style={buttonStyle} handler={() => {if (logoutPass[0] === '9821') { props.signOut()}}}/>
      </div>
      <div>
        <CommandButton controlId='hideMaintenanceButton' label='X' children={[]} style={buttonStyleHide} handler={props.hide}/>          
      </div>

      {/* second line */}
      <div>        
      </div>
      <div style={{margin: '10px'}}>
        Transfer:
      </div>
      <div style={{margin: '10px'}}>
        { log.getTracesUploadPending() 
          ? <span>Wir müssen noch Daten an den Server übertragen... </span>
          : <span>Alle Daten sind an den Server übertragen. </span> 
        }
      </div>
      <div>        
      </div>

      {/* third line */}
      <div></div>
      { errorMessages.length === 0 
        ? <div></div>
        : <div style={{margin: '10px'}}>
          Fehler:        
        </div>
      }
      { errorMessages.length === 0 
        ? <div></div>
        : <div className='errorsBox' style={{borderWidth: '2px', borderColor: 'rgb(44,77,255)', borderStyle: 'solid', margin: '10px'}}>
            <ul>{errorMessages}</ul>
          </div>
      }
    </div>
  );


  function createMessageEntry(message: string, key: string) : JSX.Element {
    const parts = message.split('[Token:]');
    return <li key={key} className="tooltip">
      { parts.length > 1 ? <span className="tooltiptext">{parts[1]}</span> : <span className="tooltiptext">no token</span> }
      {parts[0]}
      </li>
  }
}



