/**
 * Configuration of the 'tracesSending' store in the IndexDB.
 * 
 * This store keeps the bunch of trace log messages currently on their way to the server
 * and a timestamp flag signalling a currently running transmission.
 *    
 * The store contains two entries:
 *  - key === tracesSendingBunchKeyValue: The bunch of trace log messages 
 *  - key === tracesSendingFlagKeyValue: The start time of the currently running transmission
 */
import { isTraceLogMessage, TraceLogMessage } from "../utils/ApiCalls";

/**
 * The name of the 'tracesSending' store.
 */
export const tracesSendingStoreName = 'tracesSending'

/**
 * The key path of the 'tracesSending' store.
 */
export const tracesSendingKeyPath = 'current';

/**
 * The key value for the bunch entry in the 'tracesSending' store.
 */
export const tracesSendingBunchKeyValue = 'bunch';

/**
 * The key value for the executing flag entry in the 'tracesSending' store.
 */
export const tracesSendingFlagKeyValue = 'flag';


/**
 * The type of objects poplating the bunch key of the 'tracesSending' object store.
 */
export interface TracesSendingBunchEntry {
  current: string,
  messages: TraceLogMessage[]
}

export function isTracesSendingBunchEntry(candidate: any) : candidate is TracesSendingBunchEntry {  
  try {
    return (
      typeof candidate.current === 'string' 
      && Array.isArray(candidate.messages) && candidate.messages.every(isTraceLogMessage)
    );
  }
  catch(error) {
    return false;
  }
}

/**
 * The type of objects poplating the flag key of the 'tracesSending' object store.
 */
 export interface TracesSendingFlagEntry {
  current: string,
  started: number
}

export function isTracesSendingFlagEntry(candidate: any) : candidate is TracesSendingFlagEntry {  
  try {
    return (
      typeof candidate.current === 'string' 
      && typeof candidate.started === 'number' 
    );
  }
  catch(error) {
    return false;
  }
}
