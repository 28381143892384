import React from 'react';

/**
 * A text input field to gather a string parameter.
 */
export default function TextInput(props: {controlId: string, stateAccess: [string, (value: string) => void], width?: number}) : JSX.Element {
  const [value, setter] = props.stateAccess;
  const safeWidth = props.width === undefined ? 50 : props.width
  return <input 
            data-cy={props.controlId}
            className='TextInput'
            style={{width: safeWidth + 'px'}}
            value={value}
            onChange={event => setter(event.target.value)}
  />
}


