import { AssessmentSnapshot } from "../utils/ApiCalls";
import { instancesSingleKeyValue, isInstancesEntry } from "./InstancesStore";
import { isSnapshotsEntry, SnapshotsEntry } from "./SnapshotsStore";
import Logger from '../utils/Logger';

/**
 * Methods working mainly with the 'snapshots' store.
 */

/**
 * Store an assessment snapshot for the given assessmentId in the local database.
 * 
 * Storing a snapshot for an assessmentId that already stored a snapshot previously 
 * will overwrite the old snapshot.
 */
export function storeSnapshotInDb(db: IDBDatabase, instanceId: string, assessmentId: string, snapshot: AssessmentSnapshot, log: Logger) : void {
  const transaction = db.transaction(['snapshots', 'instances'], 'readwrite');
  transaction.onerror = (event) => {
    log.error(`Store snapshot transaction failed: ${transaction.error}`);
  }
  
  const instanceStore = transaction.objectStore('instances');
  const instanceRequest = instanceStore.get(instancesSingleKeyValue);
  instanceRequest.onsuccess = (event) => {
    const instanceInfo : unknown = instanceRequest.result;
    if (!isInstancesEntry(instanceInfo)) {
      log.error(`Invalid instance id entry found in database:`, instanceInfo);
      return;
    }
    if (instanceInfo.instanceId !== instanceId) {
      log.log(`Did not store snapshot since other instance became active: ${instanceInfo.instanceId} - ${instanceId}`);
      return;
    }
    const snapshotStore = transaction.objectStore('snapshots');
    const newEntry : SnapshotsEntry = { assessmentId: assessmentId, data: snapshot};
    const putRequest = snapshotStore.put(newEntry);
    putRequest.onerror = (event) => {
      log.error(`Storing the assessment snapshot failed: ${putRequest.error}`);
    }
  }
  instanceRequest.onerror = (event) => {
    log.error(`Storing the assessment snapshot failed: Could not check instance ID: ${instanceRequest.error}`);
  }
}


/**
 * Return a promise that returns the last assessment snapshot for the given assessmentId from the local database.
 * 
 * The promise resolves succussfully even if the database access fails (in which case it return 'undefined').
 */
export function getSnapshotFromDb(db: IDBDatabase, assessmentId: string, log: Logger) : Promise<AssessmentSnapshot | undefined> {

  return new Promise<AssessmentSnapshot | undefined> ((resolve, reject) => {
    var backup: AssessmentSnapshot | undefined;
    const transaction = db.transaction(['snapshots'], 'readonly');
    transaction.onerror = (event) => {
      log.error(`Retrieve snapshot transaction failed: ${transaction.error}`);
      resolve(undefined);
    }
    transaction.oncomplete = (event) => {
      resolve(backup);
    }
    
    const objectStore = transaction.objectStore('snapshots');
    const request = objectStore.get(assessmentId);
    request.onerror = (event) => {
      log.error(`Retrieving assessment snapshot from DB failed: ${request.error}`);
    }	
    request.onsuccess = (event) => {
      if (request.result === undefined) {
        backup = undefined;
      } else {
        const entry : unknown = request.result;
        if (!isSnapshotsEntry(entry)) {
          log.error('Retrieve assessment snapshot from DB returned invalid structure!');
          backup = undefined;
        } else {
          backup = entry.data;
        }
      }
    }  
  })
}

/**
 * Drop the assessment snapshot for the given assessmentId from the local database.
 */
export function dropSnapshotFromDb(db: IDBDatabase, assessmentId: string, log: Logger) : void {

  const transaction = db.transaction(['snapshots'], 'readwrite');
  transaction.onerror = (event) => {
    log.error(`Drop snapshot transaction failed: ${transaction.error}`);
  }
  
  const snapshotStore = transaction.objectStore('snapshots');
  const dropRequest = snapshotStore.delete(assessmentId);
  dropRequest.onerror = (event) => {
    log.error(`Dropping the assessment snapshot failed: ${dropRequest.error}`);
  }
}
