import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ControllerConfiguration, downloadControllerConfig, ItemSize, OidcConfig } from './utils/FileDownload';
import LocalDatabase from './database/LocalDatabase';
import { v4 } from 'uuid'; 
import TaskSequencer from './controller/TaskSequencer';
import Authenticator from './auth/Authenticator';
import BufferedTransmitter from './controller/BufferedTransmitter';
import { configure } from './controller/MainController';
import MessageReceiver from './controller/MessageReceiver';
import TraceLogsController from './controller/TraceLogsController';
import SnapshotsController from './controller/SnapshotsController';
import ScoringController from './controller/ScoringController';
import { AuthProvider } from 'oidc-react';
import { AuthWrapper } from './auth/AuthWrapper';
import { AuthProviderMock } from './authmock/AuthProviderMock';
import Logger, { LoggerContext } from './utils/Logger';
import { getMessageSystemDownMain, getMessageSystemDownSecondary, isServerInternalError} from './utils/TextUtils';

/**
 * The entry point into the application.
 * 
 * This is the layer that sets up all components: 
 *  - The database in the browser (IndexDB).
 *  - The receiver for messages coming in from the CBA runtime.
 *  - The task sequencer that decides which task to play next.
 *  - The authenticator managing the Web-Service API authentication.
 *  - The transmitter that sends buffered trace log packets.
 *  - The listeners in the message receiver.
 *  - The IFrame that contains the CBA runtime 
 *    wrapped in the authentication loop and the competitor detection loop.
 * 
 * The 'controller' is not a component (for now). 
 * It is implemented as a set of listeners registered
 * in the message receiver.
 *
 */

// The central logging facility that classifies and keeps messages.
const errorMessages : string[] = [];
var tracesToSendPending : boolean = false;
var tracesSendingEmpty : boolean = false;
var changesCallback : () => void = () => {}
var systemDownCallback : () => void = () => {}
const logKeeper : Logger = {
  log: (message, object) => console.log(message, object),
  info: (message, object) => console.info(message, object),
  warn: (message, object) => console.warn(message, object),
  error: (message, object) => 
    {
      console.error(message, object);
      const timeAndMessage = `${new Date().toLocaleTimeString()}: ${message}`
      if (typeof object !== 'undefined' && object !== null) {
        try {
          errorMessages.push(`${timeAndMessage} - ${JSON.stringify(object)}`);
        } catch (error) {
          errorMessages.push(`${timeAndMessage} - <object not stringifiable>`);
        }
      } else {
        errorMessages.push(timeAndMessage);
      }
      changesCallback();
    },
  getCollectedMessages: () => errorMessages,
  
  setTracesToSendPending: (value : boolean) => { tracesToSendPending = value; changesCallback();},
  setTracesSendingEmpty: (value : boolean) => { tracesSendingEmpty = value; changesCallback();},
  getTracesUploadPending: () => tracesToSendPending || !tracesSendingEmpty,
  
  setChangesCallback: (callback) => {changesCallback = callback;},
  
  signalSystemDown: () => {systemDownCallback()},
  setSystemDownCallback: (callback) => {systemDownCallback = callback;}
};

// We start the application rendering after fetching our controller configuration.
downloadControllerConfig(logKeeper)
.then((controllerConfiguration: ControllerConfiguration) => {
  // Create a globally unique ID for us (i.e. the execution environment instance that we are).
  const myInstanceId = v4();
  
  // Access the local database that coordinates execution environment instances and is used to buffer data:
  const localDatabase: LocalDatabase = new LocalDatabase(logKeeper);
  localDatabase.openDb(myInstanceId);
  
  // The message receiver accepts messages coming in from the task player in the CBA runtime.
  const messageReceiver : MessageReceiver = new MessageReceiver(logKeeper)
  messageReceiver.startReceiving();
  
  // The task sequencer that decides which task to run next:
  const taskSequencer : TaskSequencer = new TaskSequencer(logKeeper);

  // The authenticator that does the OpenIDConnect authentication stuff:
  const authenticator : Authenticator = new Authenticator();

  // We configure a "direct log out redirect" without involving the OIDC mechanism if a URL is configured for that:
  if (controllerConfiguration.directLogOutUrl !== undefined) {
    const redirectUrl = controllerConfiguration.directLogOutUrl;
    authenticator.setSignOut(() => {
      window.location.href = redirectUrl;
    });
  }

  // The SoftwareDriven server expects trace data and snapshots as a string that contains the JSON-string. 
  // Our test server expects all transmitted data as simple JSON-strings. 
  const additionalQuote : boolean = controllerConfiguration.additionalQuotesAroundSentJsonData === undefined ? true : controllerConfiguration.additionalQuotesAroundSentJsonData;

  // The controller for the transmissions of buffered trace log messages to the management server.
  const bufferedTransmitter : BufferedTransmitter = new BufferedTransmitter(authenticator, controllerConfiguration.serverAccess.traceUrl, additionalQuote, localDatabase, logKeeper);
  // We run the transmission cycle from now an until we are completely shut down or blocked by another instance:
  // We transmit stuff remaining from earlier sessions as soon as possible and retry transmissions as long as possible. 
  // (A call without authentication token does almost nothing. A call without data to transmit will inspect the local database but will not call the server.)
  // If another instance becomes active that instance will do the sending of all trace log data put into the database.
  bufferedTransmitter.startTransmissions();

  // The trace logs controller will process the trace log messages coming in from the task player:
  const traceLogsController = new TraceLogsController(logKeeper);
  traceLogsController.configure(messageReceiver, bufferedTransmitter, localDatabase);
  
  // The snapshots controller will trigger and process the full tasks state messages from the task player:
  const snapshotsController = new SnapshotsController(logKeeper);
  snapshotsController.configure(
      messageReceiver, 
      taskSequencer,
      authenticator, 
      localDatabase, 
      controllerConfiguration.controllerVersion, 
      controllerConfiguration.serverAccess.traceUrl,
      additionalQuote);

  // The scoring controller will process the scoring result messages from the task player:
  const scoringController = new ScoringController(logKeeper);
  scoringController.configure(
    messageReceiver,
    authenticator, 
    localDatabase,
    controllerConfiguration.controllerVersion, 
    controllerConfiguration.serverAccess.traceUrl,
    additionalQuote);

  
  // The main controller initializes the task player and processes the task switch requests coming in from the task player:
  configure(
    controllerConfiguration,
    messageReceiver, 
    taskSequencer, 
    authenticator,
    localDatabase, 
    traceLogsController,
    snapshotsController, 
    scoringController,
    logKeeper);

  // Use a default size of 768x1024 if no item size is configured:
  const itemSize : ItemSize = controllerConfiguration.itemSize === undefined ? { height: 768, width : 1024} : controllerConfiguration.itemSize;

  // Use the autentication configuration from the config file:
  const oidcConfigSettings : OidcConfig = controllerConfiguration.oidcConfig;

  const oidcConfig = {
    authority: oidcConfigSettings.authority,
    clientId: oidcConfigSettings.clientId,
    clientSecret: oidcConfigSettings.clientSecret,
    redirectUri: oidcConfigSettings.redirectUri,
    postLogoutRedirectUri: oidcConfigSettings.redirectUri,
    automaticSilentRenew: true
  }


  if (controllerConfiguration?.useMockAuthentication === true) {
    ReactDOM.render( 
      <LoggerContext.Provider value={logKeeper}>
        <AuthProviderMock 
          serverLoginUrl={controllerConfiguration.serverAccess.loginUrl}
          itemSize={itemSize}
          localDatabase={localDatabase}
          bufferedTransmitter={bufferedTransmitter}
          snapshotsController={snapshotsController} 
          oidcConfig={oidcConfig} 
          authenticator = {authenticator}
        />
      </LoggerContext.Provider>
      ,
      document.getElementById('ee4macoRoot')
    );  
  } else {
    ReactDOM.render(
      <LoggerContext.Provider value={logKeeper}>
        <AuthProvider {...oidcConfig} >
          <AuthWrapper 
              serverLoginUrl={controllerConfiguration.serverAccess.loginUrl} 
              itemSize={itemSize} 
              localDatabase={localDatabase}
              bufferedTransmitter={bufferedTransmitter}
              snapshotsController={snapshotsController}        
              authenticator = {authenticator}
          />
        </AuthProvider>
      </LoggerContext.Provider>
      ,
      document.getElementById('ee4macoRoot')
    );  
  }

})
.catch((error) => {
  logKeeper.error(`Could not initialize assessment properly: ${error.message}`);
  if (isServerInternalError(error.message)) {
    ReactDOM.render(
      <div>
        <div>
          {getMessageSystemDownMain()}
        </div>
        <div>
          {getMessageSystemDownSecondary(false)}
        </div>
      </div>,
        document.getElementById('ee4macoRoot')
    );
  } else {
    ReactDOM.render(
      <div>
        {error.message}
      </div>,
        document.getElementById('ee4macoRoot')
    );
  }  
});

