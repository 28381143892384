import {TaskIdentification, TaskSequencerState} from "../utils/ApiCalls"
import {TaskRequestDetails} from "./MessageReceiver";
import Logger from '../utils/Logger';


/**
 * The component making all next/previous/cancel task decisions. 
 * 
 * We keep a memory of available tasks and the currently running task. 
 */
export default class TaskSequencer {
  private log: Logger;

  private currentTaskIndex : number | 'not set' = 'not set';
  private tasks : TaskIdentification[] = [];

  public initialize(tasksOfAssessment: TaskIdentification[]) : void {
    this.currentTaskIndex = 0;
    this.tasks = tasksOfAssessment;
  }

  constructor(log : Logger) {
    this.log = log;
  }

  /**
   * Get the first task to run in our session. 
   * 
   * This will be the task that was interrupted in a previous session of the user's assessment
   * or the first task in the assessement task list if there is no snapshot of a previous assessment session available. 
   */
  public firstTask() : TaskIdentification | undefined {
    return this.currentTaskIndex === 'not set' || this.tasks.length <= this.currentTaskIndex ? undefined : this.tasks[this.currentTaskIndex];
  }

  /**
   * Get the next step as response to a 'cancel' request from the CBA runtime. 
   * 
   * This will advise to show a new login dialog.
   */
  public cancel() : Decision {
    if (this.currentTaskIndex === 'not set' || this.tasks.length <= this.currentTaskIndex) {
      this.log.error(`Task sequencer is not initialized properly. This blocks all task switches.`);
      return { type: 'blocked', reason: 'Task sequencer not initialized properly.' };
    }
    return { type: 'login', oldTask: this.tasks[this.currentTaskIndex]};
  }

  /**
   * Get the next step as response to a 'next-task' request from the CBA runtime. 
   * 
   * This will advise to show the next task in the assessment's task list.
   */
   public nextTask() : Decision {
    return this.switchAndReturnTask((currentIndex) => currentIndex + 1, 'no next task');
  }

  /**
   * Get the next step as response to a 'back-task' request from the CBA runtime. 
   * 
   * This will block since we don't allow back task switches in ee4maco.
   */
   public backTask() : Decision {
    return { type: 'blocked', reason: 'back task forbidden'}
  }


  /**
   * Get the next step as response to an explicit 'switch-to-task' request from the CBA runtime. 
   * 
   * This will advise to show the reqested task in the assessment's task list.
   * It will block if the switch would go back to an old task or would restart the current task.
   */
  public goToTask(request: TaskRequestDetails) : Decision {
    if (this.currentTaskIndex === 'not set' || this.tasks.length <= this.currentTaskIndex) {
      this.log.error(`Task sequencer is not initialized properly. This blocks all task switches.`);
      return { type: 'blocked', reason: 'Task sequencer not initialized properly.' };
    }
    const matchingTaskIndex = this.findMatchingTask(request);
    if (matchingTaskIndex >= 0 && matchingTaskIndex <= this.currentTaskIndex) {
      return {type: 'blocked', reason: 'implicit back task forbidden'}
    }
    return this.switchAndReturnTask(
      (_) => matchingTaskIndex,
      `Task ${request.task} ${request.item === undefined ? 'with item unspecified' : ('in item ' + request.item)} and in scope ${request.scope} is not part of the assessment configuration.`);
  }

  /**
   * Get our internal state representation for use in an assessment snapshot.
   */
  public getInternalState() : TaskSequencerState {
    return {
      currentTaskIndex: this.currentTaskIndex
    }
  }

  /**
   * Reset our internal state by loading our internal state representation obtained from an assessment snapshot.
   */
  public loadInternalState(state: TaskSequencerState) : void {
    this.currentTaskIndex = state.currentTaskIndex;
  }

  /**
   * Calculate the index to pick using the callback, check that it is in range, switch our internal state to that task and return the switch task advice. 
   */
  private switchAndReturnTask(getIndexToPick: (currentIndex : number) => number, failureMessage: string ) : Decision {
    if (this.currentTaskIndex === 'not set'|| this.tasks.length <= this.currentTaskIndex) {
      this.log.error(`Task sequencer is not initialized properly. This blocks all task switches.`);
      return { type: 'blocked', reason: 'Task sequencer not initialized properly.' };
    }
    const oldTask = this.tasks[this.currentTaskIndex];
    const indexToPick = getIndexToPick(this.currentTaskIndex);
    if (indexToPick > this.tasks.length - 1) return { type: 'finished', oldTask};
    if (indexToPick < 0 || indexToPick > this.tasks.length - 1) return { type: 'blocked', reason: failureMessage };
    this.currentTaskIndex = indexToPick;
    return { type: 'taskSwitch', oldTask, nextTask: this.tasks[this.currentTaskIndex]};
  }

  /**
   * Find a task in the assessment's task list that matched the given task request.
   * 
   * We return -1 if there is no matching task.
   */
  private findMatchingTask(request: TaskRequestDetails) : number {
    if (!request.item) return this.tasks.findIndex((candidate) => request.task === candidate.task && request.scope === candidate.scope)
    return this.tasks.findIndex((candidate) => request.item === candidate.itemName && request.task === candidate.task && request.scope === candidate.scope)
  }
  
}

/**
 * The decision returned at the requests like cancel, nextTask etc.
 */
export type Decision = 
  { 
    type: 'login',
    oldTask: TaskIdentification,
  } | 
  {
    type: 'taskSwitch',
    oldTask: TaskIdentification,
    nextTask: TaskIdentification,
  } | 
  { 
    type: 'finished',
    oldTask: TaskIdentification
  } | 
  {
    type: 'blocked'
    reason: string
  }
