import { v4 } from 'uuid'; 
import MessageReceiver, { TaskRequestDetails, RequestType } from "./MessageReceiver";
import { sendMessageToTaskPlayer } from "./MessageSender";
import { 
  ControllerConfiguration, 
  downloadItemConfig, 
  ServerAccess,
} from "../utils/FileDownload"; 
import TaskSequencer, { Decision } from "./TaskSequencer";
import { AssessmentConfiguration, callGetSnapshot, callGetTaskList, callSignalAssessmentComplete, ItemAccessInfo, AssessmentSnapshot, TaskIdentification, callSignalAssessmentTransmissionComplete } from '../utils/ApiCalls';
import Authenticator from '../auth/Authenticator';
import LocalDatabase from '../database/LocalDatabase';
import SnapshotsController from './SnapshotsController';
import TraceLogsController, { delimitTaskInTraceLogs } from './TraceLogsController';
import { dropAssessmentIdInWebStorage, setAssessmentIdInWebStorage } from '../utils/WebStorageUtils';
import ScoringController from './ScoringController';
import Logger from '../utils/Logger';
import { getMessageSystemDownMain, getMessageSystemDownSecondary, isServerInternalError} from '../utils/TextUtils';
import { buildSessionIdString } from './BufferedTransmitter';

/**
 * The controller coordinating init and task switch related actions required to run the assessment in the task player.
 * 
 * The controller's activity is triggered by the events coming in from the CBA runtime once the task player has started there.
 * 
 * The task-player-ready event coming in triggers configuration of the task player:
 *  - Set session ID.
 *  - Configure trace log verbosity and route trace logs to our message receiver.
 *  - Set the user ID for the task player.
 *  - Write the ee4maco version into the trace log.
 *  - Look for a snapshot of a previous assessment session.
 *  - Initialize task sequencer and task player taking the snapshot and configuration data retrieved from the management server into account.
 *  - Download item configurations and send them to the task player.
 *  - Obtain the first task to run from the task sequencer and start it on the task player. 
 * 
 * The task-switch-request events trigger task-switch responses determined by our task sequencer logic:
 *  - The controller informs the task sequencer about the request. 
 *  - The task sequencer tells the controller what to do next: Show a login dialog or start a task. 
 *  - The controller stops the currently running task and 
 *     * either shows a login dialog on the task player 
 *     * or starts the next task on the task player.
 * 
 * Currently this controller is just a method that configures some listeners in the message receiver.
 * 
 */

/**
 * Establish the controller, i.e. configure listeners in the message receiver. 
 * 
 * This defines our response to the messages coming in from the task player running in the CBA runtime. 
 * 
 * The controller's activity is driven by the messages coming in from the task player: 
 * - The 'ready' message triggers the configuration and the start of the first task.
 * - Task switch request messages trigger task selection and task stop/start respones. 
 */
export function configure(
  controllerConfig: ControllerConfiguration, 
  messageReceiver: MessageReceiver, 
  taskSequencer: TaskSequencer, 
  authenticator: Authenticator, 
  localDatabase: LocalDatabase,
  traceLogsController: TraceLogsController,
  snapshotsController: SnapshotsController,
  scoringController : ScoringController,
  log: Logger
) 
{
  // What to do once a task switch request arrives: Get next action from task sequencer and run it.
  messageReceiver.setTaskSwitchRequestListener((sendingWindow: MessageEventSource, request: RequestType, requestDetails?: TaskRequestDetails) => {
    
    const decision = getNextAction(request, requestDetails, taskSequencer);
    switch (decision.type) {
      case 'blocked': 
        log.info(`Cannot follow switch request ${request}: ${decision.reason}. We ignored the request.`)
        return;
      case 'finished': 
        processFinishedRequest(sendingWindow, controllerConfig.serverAccess, authenticator, localDatabase, snapshotsController, scoringController, traceLogsController, decision.oldTask, log);
        return;
      case 'login': 
        processLoginRequest(sendingWindow, authenticator, snapshotsController, scoringController, traceLogsController, decision.oldTask);
        return;
      case 'taskSwitch': 
        processTaskSwitchRequest(sendingWindow, snapshotsController, scoringController, decision.oldTask, decision.nextTask);
        return;
      default: 
        const _exhaustiveCheck : never = decision;
        return _exhaustiveCheck;
    }

  })

  // What to do once the task player is ready: 
  // Configure the task player and start the first task.
  messageReceiver.setPlayerReadyListener((sendingWindow) => {
    intializeSessionWithTaskPlayerReady(
      sendingWindow, 
      controllerConfig.controllerVersion, 
      controllerConfig.serverAccess, 
      controllerConfig.mathJaxCdnUrl, 
      taskSequencer, 
      authenticator, 
      localDatabase, 
      snapshotsController, 
      log);
  });

}
/**
 * Determine the next action by asking the task sequencer. 
 */
function getNextAction(
  request: RequestType, 
  requestDetails: TaskRequestDetails | undefined, 
  taskSequencer: TaskSequencer
  ) : Decision
{
  switch (request) {
    case 'cancelTask': return taskSequencer.cancel();
    case 'nextTask': return taskSequencer.nextTask();
    case 'previousTask': return taskSequencer.backTask();
    case 'goToTask': {
      if (requestDetails === undefined) return { type: 'blocked', reason: 'Task specification is missing in goToTask request.' }
      return taskSequencer.goToTask(requestDetails);
    }
    default: {
      const _exhaustiveCheck: never = request;
      return _exhaustiveCheck;
    }
  }
}

/**
 * Process a request by the task player to finish the assessment.
 * 
 * We signal the completion of the assessement, stop the task in the task player, transmit an assessment snapshot to the server, 
 * flush the trace log buffer, signal the completion of the assessment transmissions to the management server
 * and log out completely.
 * 
 * We make sure that we do not signal assessment transmission completion before the snapshot 
 * and the trace logs are transmitted. 
 * 
 * In case of transmission failures we write warnings to the console and logout nevertheless.
 */
function processFinishedRequest(
  targetWindow: MessageEventSource,
  serverAccess: ServerAccess,
  authenticator: Authenticator, 
  localDatabase: LocalDatabase,
  snapshotsController : SnapshotsController,
  scoringController : ScoringController,
  traceLogsController: TraceLogsController, 
  oldTask: TaskIdentification,
  log: Logger
  ) : void
{
  // stop task immediately to avoid multiple clicks on the finish button
  stopTask(targetWindow);
  // block validation for the assessment on the server:
  callSignalAssessmentComplete(serverAccess.traceUrl, authenticator.getAuthenticationTokenServerString())
  .then(() => 
    waitForDataTansmissionToServer(targetWindow, snapshotsController, scoringController, traceLogsController, oldTask)
    .then(() => {
      snapshotsController.stopDbTransmissions();
      localDatabase.dropSnapshotFromDb(buildAssessmentId(authenticator.getStudentId(), authenticator.getRunId()));
      logout(targetWindow);
      dropAssessmentIdInWebStorage();
      // signal end of all transmissions for the assessment:
      return callSignalAssessmentTransmissionComplete(serverAccess.traceUrl, authenticator.getAuthenticationTokenServerString())
      .then(() => {
        if(log.getTracesUploadPending())
        {
          setFinishFailedMessage(targetWindow);
        } else {
          authenticator.logout();
        }
      })
      .catch((error) => {
        log.error(`Signal Übertragungsende ist gescheitert: ${error.message}`);
        setFinishFailedMessage(targetWindow);
      })      
    })
  )
  .catch((error) => {
    log.error(`Signal Bearbeitungsende ist gescheitert: ${error.message}`);
    setFinishFailedMessage(targetWindow);
  })      
}


/**
 * Process a request by the task player to show a new login.
 * 
 * We stop the currently running task, trigger transmission of an assessment snapshot 
 * and the buffered trace log and log out completely.
 * 
 * We do not wait until the transmissions are done before we log out.
 */
function processLoginRequest(
  targetWindow: MessageEventSource,
  authenticator: Authenticator,
  snapshotsController : SnapshotsController,
  scoringController : ScoringController,
  traceLogsController: TraceLogsController,
  oldTask: TaskIdentification
  ) : void 
{
  // stop task immediately to avoid multiple clicks on the cancel button
  stopTask(targetWindow);
  waitForDataTansmissionToServer(targetWindow, snapshotsController, scoringController, traceLogsController, oldTask)
  .then(() => {
    snapshotsController.stopDbTransmissions();
    logout(targetWindow);
    dropAssessmentIdInWebStorage();
    authenticator.logout();
  })
}

/**
 * Process a request by the task player to switch to another task.
 * 
 * We stop the currently running task, trigger a transmission of 
 *  - an assessment snapshot,
 *  - the old scoring result and
 *  - the buffered trace log 
 * and start the new task.
 */
function processTaskSwitchRequest(
  targetWindow: MessageEventSource,
  snapshotsController : SnapshotsController,
  scoringController : ScoringController,
  oldTask: TaskIdentification,
  nextTask: TaskIdentification, 
) : void
{  
  // stop task immediately to avoid multiple clicks on the next button
  stopTask(targetWindow);
  // Transmit the full tasks state:
  snapshotsController.immediateServerTransmission(targetWindow, oldTask)
  .then(() => scoringController.immediateServerTransmission(targetWindow, oldTask))
  .then(()=> {
    // Make sure the trace log entries for the stopped task are not mixed with trace log entries for the next task
    // in a single trace log message.
    // (Delimiting tasks might be convenient for the server. It is not necessary for our internal processing.)
    delimitTaskInTraceLogs(targetWindow);
    // Drop the full tasks state in the task player to reduce its size for the next task:
    // (We don't allow going back to old tasks and tasks have not "back" references in ee4maco.)
    clearTasksState(targetWindow);
    startTask(nextTask, targetWindow);
  });
}

/**
 * Return a promise that waits for the transmission of 
 * the task related data to our IndexDB and the management server.
 * 
 * The promise never rejects. In case of transmission failures it just writes warnings to the log.
 * 
 * We wait for the transmission of
 *  - the assessment snapshot (into IndexDB and to management server)
 *  - the scoring result (to management server)
 *  - the trace log data (to management server via buffer in IndexDB)
 */
 function waitForDataTansmissionToServer(
  targetWindow: MessageEventSource,
  snapshotsController : SnapshotsController,
  scoringController : ScoringController,
  traceLogsController: TraceLogsController,
  oldTask: TaskIdentification
) : Promise<void> {
  // Wait for the transmissions to complete by scanning the processing result collectors:
  return snapshotsController.immediateServerTransmission(targetWindow, oldTask)
    .then(() => scoringController.immediateServerTransmission(targetWindow, oldTask))
    .then(() => traceLogsController.doImmediateEndOfSessionTransmission(targetWindow));
}


/**
 * Configure everything and start the first task once the task player is ready.
 * 
 *  - Assign a trace context id for the session in the task player.
 *  - Configure the verbosity of the trace data and route it to our message receiver.
 *  - Set the user Id in the task player and in the WebStorage.
 *  - Put our ee4maco version into the trace log (via TaskPlayer-API call).
 *  - Set the task sequencer.
 *  - Clear the full state in the CBA runtime.
 *  - Obtain the best available assessment snapshot and continue the configuration with that. 
 */
 function intializeSessionWithTaskPlayerReady(
  targetWindow: MessageEventSource,
  controllerVersion: string,
  serverAccess: ServerAccess, 
  mathJaxCdnUrl: string | undefined,
  taskSequencer: TaskSequencer,
  authenticator: Authenticator,
  localDatabase: LocalDatabase,
  snapshotsController: SnapshotsController, 
  log: Logger
  ) : void 
  {     
    sendMessageToTaskPlayer(targetWindow, {eventType: 'setTraceContextId', contextId: buildSessionIdString(authenticator.getSessionId())});
    sendMessageToTaskPlayer(targetWindow, {
      eventType: 'setTraceContentFilter',
      replaySupport: false,
      withExtendedMetaData: true,
      withoutItemConfiguration: true,
      withoutScoring: true,
      withoutSnapshot: true
    });
    sendMessageToTaskPlayer(targetWindow, {
      eventType: 'setTraceLogTransmissionChannel', 
      channel: 'postMessage', 
      targetWindowType: 'parent',
      // TODO: improve target origin:
      targetOrigin: '*',
      interval: 2000
    });
    setWaitingMessage(targetWindow);

    const assessmentId = buildAssessmentId(authenticator.getStudentId(), authenticator.getRunId());
    const userToken = authenticator.getAuthenticationTokenServerString();    
    
    setUserId(authenticator.getStudentId(), targetWindow);
    setAssessmentIdInWebStorage(assessmentId);
    putMessageToTrace(targetWindow, `Controller is ee4maco version ${controllerVersion}`);
    setScalingConfiguration(targetWindow);
    setTaskSequencer(targetWindow);
    clearTasksState(targetWindow);
    withBestSnapshot(localDatabase, serverAccess, userToken, assessmentId, '(Wieder-)Aufbau der Sitzung zum Bearbeitungsschlüssel fehlerhaft', log)
      .then((oldSnapshot) => {
        configureTasksAndStartFirstTask(targetWindow, serverAccess, userToken, taskSequencer, snapshotsController, mathJaxCdnUrl, oldSnapshot, log);
      })
      .catch((reason) => {
        setSystemFailureMessage(targetWindow);
      });
}

function buildAssessmentId(studentId : string, runId : string) : string {
  return `${studentId}_${runId}`;
}

/**
 * Return a promise that resolves to the best assessment snapshot that is available on the local database and the server API.
 * 
 * We try to get a snapshot from the local database first. 
 * If there is a snapshot and it was stored recently (less than a couple of minutes ago)
 * we return that one.
 * 
 * Otherwise we also ask the management server for a snapshot.
 * If we have no snapshot at all now, we return undefined.
 * If we have just one snapshot, we return that one.
 * If we have two snapshots, we take the more recent one.
 * 
 * The promise resolves succussfully even if
 * - database access fails or 
 * - server access fails with a status code below 500.
 * It returns 'undefined' in these cases.
 * 
 * For a server access failure signalling an internal server error (status code >= 500) the promise rejects.
 */
function withBestSnapshot(
  localDatabase: LocalDatabase, 
  serverAccess: ServerAccess, 
  authToken: string, 
  assessmentId: string,
  errorPrefix: string,
  log: Logger) : Promise<AssessmentSnapshot | undefined> 
{
  return new Promise((resolve, reject) => {
    localDatabase.getSnapshotFromDb(assessmentId)
    .then((snapshotFromDb) => {
      if (snapshotFromDb !== undefined && snapshotFromDb.creationTime > new Date().getTime() - (180000)) {
        log.log(`Use recent local snapshot: ${snapshotFromDb.creationTime}`);
        resolve(snapshotFromDb);
      }
      else {
        return callGetSnapshot(serverAccess.loginUrl, authToken)
        .then(snapshotFromServer => {
          resolve(pickSnapshot(snapshotFromDb, snapshotFromServer, log));
        })
        .catch((error) => {
          log.error(`${errorPrefix}: ${error.message}`);
          if (isServerInternalError(error.message)) {
            reject(error);
          } else {
            resolve(undefined);
          }
        });  
      }
    });  
  });
}

/**
 * Pick the latest snapshot.
 */
function pickSnapshot(snapshotA: AssessmentSnapshot | undefined, snapshotB: AssessmentSnapshot | undefined, log: Logger) : AssessmentSnapshot | undefined {
  if (snapshotA === undefined && snapshotB === undefined) {
    log.log(`No snapshot found.`);
    return undefined;
  }
  if (snapshotA === undefined) {
    log.log(`No local snapshot, use server snapshot.`);
    return snapshotB;
  }
  if (snapshotB === undefined) {
    log.log(`No server snapshot, use local snapshot.`);
    return snapshotA;
  }
  if (snapshotA.creationTime > snapshotB.creationTime) {
    log.log(`Local snapshot is more recent: ${snapshotA.creationTime} <-> ${snapshotB.creationTime}`);
    return snapshotA;
  }
  if (snapshotA.creationTime < snapshotB.creationTime) {
    log.log(`Server snapshot is more recent: ${snapshotA.creationTime} <-> ${snapshotB.creationTime}`);
    return snapshotA;
  }
  log.log(`Use server snapshot (same age)`);
  return snapshotB;
}

/**
 * Continue configuration of our components and the task player once the best assessment snapshot is available.
 * 
 * If there is a snapshot available we preload the task player full state with the data from there.
 * 
 * After that we obtain the task list for the assessment from the management server and
 * initialize the task sequencer with that. If there is a snapshot available we load the task sequencer
 * internal state from there. 
 * 
 * Finally we load the required items into the task player and start the first task. 
 *
 */
function configureTasksAndStartFirstTask(
  targetWindow: MessageEventSource, 
  serverAccess: ServerAccess, 
  authToken: string, 
  taskSequencer: TaskSequencer, 
  snapshotsController: SnapshotsController,
  mathJaxCdnUrl: string | undefined,
  oldSnapshot: AssessmentSnapshot | undefined,
  log: Logger 
  ) : void
{
  if (oldSnapshot !== undefined && oldSnapshot !== null) {
    preloadTasksState(targetWindow, oldSnapshot.runtimeTasksState);
  }
  callGetTaskList(serverAccess.loginUrl, authToken)
  .then((configuration) => {
    if (configuration.tasks.length < 1) {
      throw new Error(`Es sind keine Aufgaben zu bearbeiten.`);
    }
    taskSequencer.initialize(configuration.tasks);
    if (oldSnapshot !== undefined) {
      taskSequencer.loadInternalState(oldSnapshot.taskSequencerState);
      log.log(`Starting task with preloaded snapshot: first task: ${oldSnapshot.taskSequencerState.currentTaskIndex}`);
    }
    return loadItemsAndStartFirstTask(targetWindow, configuration, taskSequencer, snapshotsController, mathJaxCdnUrl, log);
  })
  .catch((error) => {
    const errorId = v4();
    const message = `Could not initialize task player properly [errorID: ${errorId}]: ${error.message} `;
    log.error(message);
    putMessageToTrace(targetWindow, `Controller could not establish assessment: ${message}`);
    if (isServerInternalError(error.message)) {
      setSystemFailureMessage(targetWindow);
    } else {
      setConfigurationFailureMessage(targetWindow, error.message, errorId);
    }
  });
}

/**
 * Return a promise that loads all required items and starts the first task as advised by the task sequencer.
 */
function loadItemsAndStartFirstTask(
  targetWindow: MessageEventSource,
  assessmentConfiguration: AssessmentConfiguration, 
  taskSequencer: TaskSequencer,
  snapshotsController: SnapshotsController,
  mathJaxCdnUrl: string | undefined,
  log: Logger
) : Promise<void> {  
    return installAllItems(targetWindow, assessmentConfiguration, mathJaxCdnUrl, log)
    .then(() => {
      const firstTask = taskSequencer.firstTask();
      if (firstTask === undefined) {
        throw new Error(`Wir können die erste Aufgabe nicht starten, weil der Ablaufplan ungültig ist.`);
      }
      startTask(firstTask, targetWindow);
      snapshotsController.startDbTransmissions(targetWindow, 5000, 300);
    });
}

/**
 * Build a promise that installs all items contained in the given assessment configuration.
 */
function installAllItems(
  targetWindow: MessageEventSource,
  assessmentConfiguration: AssessmentConfiguration, 
  mathJaxCdnUrl: string | undefined,
  log: Logger
  ) : Promise<void[]> 
{ 
  clearItems(targetWindow);
  
  // Get the names of all items that we need for the assessment:
  const requiredItems : string[] = assessmentConfiguration.tasks
    .map((taskConfiguration) => taskConfiguration.itemName)
    .filter(onlyUnique);
  
  // Get the item access information for all required items:
  const requiredItemInfos = requiredItems.map(itemName => {
    const itemInfo = assessmentConfiguration.items.find(itemEntry => itemEntry.itemName === itemName);
    if (itemInfo === undefined) {
      throw new Error(`Wir können den Inhalt ${itemName} nicht herunterladen, weil der Ablaufplan vom Server keine Details dazu enthält.`);
    }
    return itemInfo
  });

  return Promise.all(
    requiredItemInfos.map((itemInfo) => installItem(targetWindow, itemInfo, mathJaxCdnUrl, log))
  ); 
}

/**
 * Is the given index the first occurrence of the given value in the given array?
 * 
 * Using this method in a filter on an array will drop out all duplicates. 
 */
 function onlyUnique<T>(value: T, index: number, all: T[]) : boolean {
  return all.indexOf(value) === index;
}

/**
 * Return a promise that downloads the configuration for the item described by the given retrieval meta-info and installs it in the CBA runtime.
 */
function installItem(
  targetWindow: MessageEventSource,
  itemInfo: ItemAccessInfo, 
  mathJaxCdnUrl: string | undefined,
  log: Logger) : Promise<void> 
{
  return downloadItemConfig(itemInfo.itemName, itemInfo.configUrl, log)
    .then((itemConfiguration) => {
      try {
        sendMessageToTaskPlayer(targetWindow, {
          eventType: 'addItem', 
          itemConfig: itemConfiguration, 
          resourcePath: itemInfo.resourceUrl,
          externalResourcePath: itemInfo.externalResourceUrl,
          libraryPathsMap: { MathJax: mathJaxCdnUrl === undefined ? 'math-jax unknown' : mathJaxCdnUrl}
        })
      } catch (error) {
        throw new Error(`Wir können den Inhalt ${itemInfo.itemName} nicht laden: ${error}`)
      }
    })
}


/**
 * Set the waiting messages in the task player to the "system down" display.
 */
function setSystemFailureMessage(targetWindow : MessageEventSource) : void {
  sendMessageToTaskPlayer(targetWindow, {
    eventType: 'setWaitMessages',
    primary: getMessageSystemDownMain(),
    secondary: getMessageSystemDownSecondary(false)
  })
}


/**
 * Set the waiting messages in the task player to a "we cannot run the assessment" display.
 */
 function setConfigurationFailureMessage(targetWindow : MessageEventSource, message: string, errorId: string) : void {
  sendMessageToTaskPlayer(targetWindow, {
    eventType: 'setWaitMessages',
    primary: message,
    secondary: `Bitte sag deiner Lehrkraft Bescheid. Wenn Du kannst, dann teile ihr dabei diese Fehlernummer mit: ${errorId}`
  })
}


/**
 * Set the waiting messages in the task player to a "we are waiting for server transmission" display.
 */
function setWaitingMessage(targetWindow : MessageEventSource) : void {
  sendMessageToTaskPlayer(targetWindow, {
    eventType: 'setWaitMessages',
    primary: "Bitte einen Moment Geduld!",
    secondary: "Wir tauschen Daten mit dem Server aus. Sobald wir damit fertig sind, geht es weiter."
  })
}

/**
 * Set the waiting messages in the task player to a "we had problems uploading left-overs" display.
 */
function setFinishFailedMessage(targetWindow : MessageEventSource) : void {
  sendMessageToTaskPlayer(targetWindow, {
    eventType: 'setWaitMessages',
    primary: "Die Ergebnisse werden noch übertragen.",
    secondary: "Bitte lasse die Seite geöffnet und informiere deine Lehrkraft."
  })
}


/**
 * Set the scaling configuration in the task player to our fixed set of values.
 */
function setScalingConfiguration(targetWindow : MessageEventSource) : void {
  sendMessageToTaskPlayer(targetWindow, {
    eventType: 'setScalingConfiguration',
    scalingMode: 'scale-up-down',
    alignmentHorizontal: 'center',
    alignmentVertical: 'top'
  })
}

/**
 * Establish ourselves as task sequencer in CBA runtime.
 */
 function setTaskSequencer(targetWindow: MessageEventSource) {
  sendMessageToTaskPlayer(targetWindow, {eventType: 'setTaskSequencer', targetOrigin: window.location.origin, targetWindowType: 'parent'})
}

/**
 * Clear all items in the CBA runtime.
 */
function clearItems(targetWindow: MessageEventSource) {
  sendMessageToTaskPlayer(targetWindow, {eventType: 'clearItems' });
}

/**
 * Clear the full state of all tasks in the CBA runtime.
 */
function clearTasksState(targetWindow: MessageEventSource) {
  sendMessageToTaskPlayer(targetWindow, {eventType: 'clearTasksState'});
}

/**
 * Preload the full state of all tasks in the CBA runtime.
 */
function preloadTasksState(targetWindow: MessageEventSource, state: unknown) {
  sendMessageToTaskPlayer(targetWindow, {eventType: 'preloadTasksState', state});
}


/**
 * Stop the running task in the CBA runtime.
 */
function stopTask(targetWindow: MessageEventSource) : void {
  sendMessageToTaskPlayer(targetWindow, {eventType: 'stopTask'});
}

/**
 * Start a task in the CBA runtime.
 */
function startTask(toStart: TaskIdentification, targetWindow: MessageEventSource) : void {
  sendMessageToTaskPlayer(targetWindow, {eventType: 'startTask', item: toStart.itemName, task: toStart.task, scope: toStart.scope});
}

/**
 * Set the user ID in the CBA runtime.
 */
 function setUserId(userId: string, targetWindow: MessageEventSource) : void {
  sendMessageToTaskPlayer(targetWindow, {eventType: 'setUserId', id: userId});
}

/**
 * Log out the current user in the CBA runtime.
 */
function logout(targetWindow: MessageEventSource) : void {
  sendMessageToTaskPlayer(targetWindow, {eventType: 'logout'});
}

/**
 * Put a message to the trace in the CBA runtime.
 */
function putMessageToTrace(targetWindow: MessageEventSource, message: string) : void {
  sendMessageToTaskPlayer(targetWindow, {eventType: 'insertMessageInTrace', message: message });
}
