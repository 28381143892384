import React from "react";
import { LoginLoop } from "../auth/LoginLoop";
import { Auth } from "./Auth";
import { MockConfiguration } from "./MockConfiguration";
import { ItemSize } from "../utils/FileDownload";
import LocalDatabase from "../database/LocalDatabase";
import BufferedTransmitter from "../controller/BufferedTransmitter";
import SnapshotsController from "../controller/SnapshotsController";
import Authenticator from "../auth/Authenticator";

/**
 * Run the LoginLoop with a mock implementation for the authentication context 
 * that the oidc library will provide.
 */
export function AuthProviderMock(props: { 
  oidcConfig : { authority: string, clientId: string, redirectUri: string},
  itemSize: ItemSize,
  localDatabase: LocalDatabase,
  bufferedTransmitter: BufferedTransmitter,
  snapshotsController: SnapshotsController,
  serverLoginUrl: string,
  authenticator: Authenticator
}) : JSX.Element 
{
  const firstAuth : Auth = {
    userData: undefined,
    isLoading: false,
    signOut: () => new Promise((resolve) => alert(`Called signOut`)),
    signOutRedirect: () => new Promise((resolve) => alert(`Called signOutRedirect`))
  };
  const authAccess = React.useState(firstAuth);
  props.authenticator.configure(authAccess[0]);

  return (
    <div>
      <MockConfiguration authUpdater={authAccess[1]} />
      <LoginLoop 
        itemSize={props.itemSize} 
        localDatabase={props.localDatabase} 
        bufferedTransmitter={props.bufferedTransmitter} 
        snapshotsController={props.snapshotsController} 
        serverLoginUrl={props.serverLoginUrl} 
        auth= {authAccess[0]}
        signOut= {() => props.authenticator.logout()} 
      />
    </div>
  );

}
