import Logger from '../utils/Logger';

/**
 * A basic loop mechanism that allows to execute a target action in a timer loop
 * with a command to interject an immediate execution of some action.
 * 
 * The loop will never execute the action in the loop cycle in parallel 
 * with an interjected action and will not run two interjected actions in parallel.
 */
export default class LoopWithInterjection {
  private log: Logger;

  // configuration
  private loopInterval: number;
  private interjectionInterval: number;
  private targetActionProvider : () => Promise<void>;
  
  // internal status
  private loopStatus : "running" | "stopped";
  private locked : boolean;

  // handle to quit timeout
  private timeoutHandle : number | undefined;
  
  // stuff for debug messages:
  private debugLogging : boolean; 
  private nameForMessages : string;
  private cycleCount : number;
  private interjectionsCount : number;

  /**
   * Create a loop. 
   * 
   * The interjection interval should always be significantly shorter than the loop interval. 
   * Otherwise the interjections might not be 'immediate' anymore. (Their waiting timeout might not hit the unlocked phase between 
   * the current loop cycle execution and the next.)
   * 
   * We call the target action provider once per loop cycle. It should provide the action to be executed by the loop cycle.
   * 
   * We use the given "name for messages" in the debug messages that we write to the console if the "debug logging" flag is set.  
   */
  constructor(loopInterval: number, interjectionInterval: number, targetActionProvider : () => Promise<void>, nameForMessages: string, log: Logger, debugLogging: boolean) {
    this.log = log;

    this.loopInterval = loopInterval;
    this.interjectionInterval = interjectionInterval;
    this.targetActionProvider = targetActionProvider;
    
    this.loopStatus = "stopped";
    this.locked = false
    this.timeoutHandle = undefined;
    
    this.debugLogging = debugLogging;
    this.nameForMessages = nameForMessages;
    this.cycleCount = 0;
    this.interjectionsCount = 0;

    this.executeCycleActionAndReschedule = this.executeCycleActionAndReschedule.bind(this);
  }

  /**
   * Start the executions cycle. 
   * 
   * We execute our target action immediately and do further 
   * executions with a fixed interval between the executions.
   * 
   * The loop stops if the provided target action does not resolve successfully.
   */
  public startLoop() : void {
    if (this.loopStatus !== "running") {
      this.loopStatus = "running";
      this.executeCycleActionAndReschedule();
    }
  }

  /**
   * Stop executions cycle. 
   */
   public stopLoop() : void {
    if (this.loopStatus !== "stopped") {
      this.loopStatus = "stopped";
      this.clearMyTimeout();
    }
  }

  /**
   * Return a promise that executes the given action without further delay (but not in parallel with an already started loop cycle execution).
   * 
   * If other interject actions arrive while we are still waiting they might get executed before we get our turn.
   * 
   * When the action provider is called it should create an new promise and return it. If it returns a promise that was created before the call,
   * the promise might start its action in parallel with a loop cycle action or another interjected action.
   */
   public interjectAction(actionProvider: () => Promise<void>) : Promise<void> {
    const myCount = this.interjectionsCount;
    this.interjectionsCount += 1;
    
    return this.getLock()
      .then(() => { this.debugLog(`interject starts... [${myCount}]`)})
      .then(() => actionProvider())
      .then(() => { this.debugLog(`interject end       [${myCount}]`)})
      .finally(() => { this.locked = false;});
  }


  /**
   * A single loop cycle: 
   * 
   * Try to get lock and:
   * - If the lock is not available just schedule the next cycle (i.e. we do not wait for the lock).
   * - If we could get the lock, run the action, schedule the next cycle and release the lock.
   * 
   * We release the lock even if the run action fails.
   * We don't schedule the next run if the run action fails. 
   */
  private executeCycleActionAndReschedule() {
    const myCount = this.cycleCount;
    this.cycleCount += 1;

    if (this.locked) {
      this.debugLog(`skipped cycle due to lock [${myCount}]`)
      this.scheduleIfInStatusRunning();
    } else {
      this.locked = true;
      this.debugLog(`cycle starts... [${myCount}]`)
      this.targetActionProvider()
        .then(() => {
          this.debugLog(`cycle end.      [${myCount}]`)
          this.scheduleIfInStatusRunning();        
        })
        .finally(() => { this.locked = false; })
    }
  }


  /**
   * Return a promise that resolves once it has obtained the lock.
   */
  private getLock() : Promise<void> {
    return new Promise((resolve, reject) => {
      this.waitForLock(resolve);
    })
  }

  /**
   * Wait for the lock; grab it and execute the given action once it is available.
   * 
   * We test the lock and: 
   * - grab it and execute the given action if it is free.
   * - reschedule our test if it is not available.
   */
  private waitForLock(resolve: () => void) : void {
    if (this.locked) {
      window.setTimeout(this.waitForLock.bind(this, resolve), this.interjectionInterval)
    } else {
      this.locked = true;
      resolve();
    }
  }

  /**
   * Schedule another loop cycle if we are in loop status "running".
   */
   private scheduleIfInStatusRunning() {
    if (this.loopStatus === "running") {
      this.timeoutHandle = window.setTimeout(this.executeCycleActionAndReschedule, this.loopInterval);
    }
  }

  /**
   * Clear the loop cycle timeout and set the handle to undefined.
   */
  private clearMyTimeout() {
    if (this.timeoutHandle !== undefined) {
      clearTimeout(this.timeoutHandle);
      this.timeoutHandle = undefined;
    }
  }


  /**
   * Write the message to the console if the debugLogging flag is set.
   */
  private debugLog(message : string) {
    if (this.debugLogging) {
      this.log.log(`Loop ${this.nameForMessages}: ${message}`);
    }
  }
}



