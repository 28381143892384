import { InstancesEntry, instancesSingleKeyValue, isInstancesEntry } from "./InstancesStore";
import Logger from '../utils/Logger';

/**
 * Methods working mainly with the 'instances' store.
 */


/**
 * Put our instance ID into the database to signal our activity.
 */
export function initInstanceInDb(db: IDBDatabase, instanceId: string, log: Logger) : void {
  const transaction = db.transaction(['instances'], 'readwrite');
  transaction.onerror = (event) => {
    log.error('InitInstance transaction failed!');
  }
  
  const instancesStore = transaction.objectStore('instances');
  const newEntry : InstancesEntry = { level: instancesSingleKeyValue, instanceId: instanceId };
  const request = instancesStore.put(newEntry);
  request.onerror = (event) => {
    log.error(`Storing the instance id ${instanceId} failed: ${request.error}`);
  }  
  request.onsuccess = (event) => {
    log.log(`Instance id ${instanceId} stored to database.`);
  }
}

/**
 * Return a promise that checks whether some other instance became active since we opened our database connection.
 * 
 * The promise will always resolve successfully even if the database access fails (in which case it will resolve to 'undefined').
 */
export function checkConcurrentInstance(db : IDBDatabase, ourId: string, log: Logger) : Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    var competitorId : string | undefined = undefined;
    const transaction = db.transaction(['instances'], 'readonly');
    transaction.onerror = (event) => {
      log.error(`Check instance transaction failed: ${transaction.error}`);
      resolve(undefined);
    }
    transaction.oncomplete = (event) => {
      resolve(competitorId);
    }
    
    const objectStore = transaction.objectStore('instances');
    const instanceRequest = objectStore.get(instancesSingleKeyValue);
    instanceRequest.onerror = (event) => {
      log.error(`Reading instance from DB failed: ${instanceRequest.error}`);
    }	
    instanceRequest.onsuccess = (event) => {
      const dbInstance : unknown = instanceRequest.result;
      if (!isInstancesEntry(dbInstance)) {
        log.error(`Invalid instance id entry found in database`, dbInstance);
        return;
      } 
      if (dbInstance.instanceId !== ourId) {
        log.log(`Detected concurrent instance: ${dbInstance.instanceId}.`);
        competitorId = dbInstance.instanceId;
      }
    }
  
  });


}
