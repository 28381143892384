import { AssessmentSnapshot, TraceLogMessage } from "../utils/ApiCalls";
import { instancesStoreName, instancesKeyPath } from "./InstancesStore";
import { checkConcurrentInstance, initInstanceInDb } from "./InstancesUtils";
import { snapshotsKeyPath, snapshotsStoreName } from "./SnapshotsStore";
import { dropSnapshotFromDb, getSnapshotFromDb, storeSnapshotInDb } from "./SnapshotsUtils";
import { storeTraceLogTransmissionInDb, transmitTraceLogMessagesFromDb } from "./TraceLogsUtils";
import { tracesSendingKeyPath, tracesSendingStoreName } from "./TracesSendingStore";
import { tracesToSendKeyPath, tracesToSendStoreName } from "./TracesToSendStore";
import Logger from '../utils/Logger';

/**
 * Wrapper around the local database.
 * 
 * The wrapper keeps 
 * - a single instance of the IDBDatabase structure to access the local database and
 * - a globally unique ID of our maco execution environment instance 
 * 
 * It uses our globally unique instance ID to detect other instances of the maco execution environment
 * accessing the local database simultaneously.
 * 
 * The wrapper establishes the following object stores in the local database:
 * - instances: The ID of the currently active maco execution environment. 
 * - snapshots: The last assessment snapshot collected for an assessmentId. 
 * - tracesToSend: The list of trace log messages to send to the server. 
 * - tracesSending: The bunch of trace log messages currently on their way to the server. 
 */
export default class LocalDatabase {
  private log: Logger;

  // state
  private db: IDBDatabase | 'not opened' = 'not opened';
  private instanceId: string | undefined;
  
  // debug messages
  private debugLogging : boolean = false;
  private transmitTraceCallCount : number = 0;

  constructor(log : Logger) {
    this.log = log;
  }

  /**
   * Open our connection to the local database and post us there as active instance.
   */
  public openDb(instanceId: string) : void {
    if (instanceId === undefined) {
      this.log.error(`Cannot open DB without instance ID.`)
    }

    if (this.instanceId !== undefined && this.instanceId !== instanceId) {
      this.log.error(`Cannot change instance ID after first open DB call: ${this.instanceId} -> ${instanceId}` );
      return;
    }
    
    this.instanceId = instanceId;

    const request: IDBOpenDBRequest = window.indexedDB.open('StateDatabase', 1);
    request.onerror = (event: Event) => {
      this.log.error(`Opening the database failed: ${request.error}`);
    }
    request.onsuccess = (event: Event) => {
      this.db = request.result;
      initInstanceInDb(this.db, instanceId, this.log);
    }
    request.onupgradeneeded = (event) => {
      this.log.log('onupgradeneeded runs...');
      const db = request.result;
      db.createObjectStore(instancesStoreName, { keyPath: instancesKeyPath });
      db.createObjectStore(snapshotsStoreName, { keyPath: snapshotsKeyPath });
      db.createObjectStore(tracesToSendStoreName, { keyPath: tracesToSendKeyPath});
      db.createObjectStore(tracesSendingStoreName, { keyPath: tracesSendingKeyPath});
      this.log.log('onupgradeneeded done.');
    }

  }

  /**
   * Is our database access already initialized?
   */
  public isInitialized() : boolean {
    return this.db !== 'not opened';
  } 

  /**
   * Return a promise that checks whether some other instance became active since we opened our database connection.
   * 
   * The promise will always resolve successfully even if the database access fails (in which case it will resolve to 'undefined').
   */
  public checkConcurrentInstance() : Promise<string|undefined> {
    return new Promise((resolve, reject) => {
      if (this.db === 'not opened' || this.instanceId === undefined) {
        this.log.error(`Cannot check concurrent instance before openDb call`);
        resolve(undefined);
      } else {
        resolve(checkConcurrentInstance(this.db, this.instanceId, this.log));
      }
    })
  }

  /**
   * Store an assessment snapshot for the given assessmentId in the local database.
   * 
   * Storing a snapshot for an assessmentId that already stored a snapshot previously 
   * will overwrite the old snapshot.
   */
   public storeSnapshotInDb(assessmentId: string, snapshot: AssessmentSnapshot) : void {
    if (this.db === 'not opened' || this.instanceId === undefined) {
      this.log.error(`Cannot store snapshot data before openDb call`);
      return;
    }
    storeSnapshotInDb(this.db, this.instanceId, assessmentId, snapshot, this.log);    
  }

  /**
   * Return a promise that gets the last assessment snapshot for the given assessment Id from the local database.
   * 
   * The promise resolves succussfully even if the database access fails (in which case it returns 'undefined').
   */
   public getSnapshotFromDb(assessmentId: string) : Promise<AssessmentSnapshot | undefined> {
    if (this.db === 'not opened') {
      this.log.error(`Cannot get snapshot data before openDb call`);
      return Promise.resolve(undefined);
    } 
    return getSnapshotFromDb(this.db, assessmentId, this.log);
  }

  /**
   * Delete the snapshot for the given assessment Id in the local database. 
   */
  public dropSnapshotFromDb(assessmentId: string) : void {
    if (this.db === 'not opened') {
      this.log.error(`Cannot drop snapshot data before openDb call`);
      return;
    } 
    dropSnapshotFromDb(this.db, assessmentId, this.log);
  }


  /**
   * Store a trace log message in the local database.
   */
   public storeTraceLogMessageInDb(traceLogMessage: TraceLogMessage) : void {
    if (this.db === 'not opened') {
      this.log.error(`Cannot store trace log data before openDb call`);
      return;
    }
    storeTraceLogTransmissionInDb(this.db, traceLogMessage, this.log);
  }

  /**
   * Return a promise that transmits all trace log messages buffered in the DB to the management server. 
   * 
   * The promise resolves succussfully in any case: no messages pending, failure in server call, failure in DB transaction.
   */
  public transmitTraceLogMessagesFromDb(
    sendCall : (messages : TraceLogMessage[]) => Promise<TraceLogMessage[]>) : Promise<void>
  {
    const myCallIndex = this.transmitTraceCallCount;
    this.transmitTraceCallCount += 1;
    this.debugLog(`transmit trace starts... [${myCallIndex}]`)
    const db : IDBDatabase | 'not opened' = this.db;
    if (db === 'not opened') {
      this.log.error(`Cannot transmit trace log data before openDb call`);
      return Promise.resolve();
    } 
    return transmitTraceLogMessagesFromDb(db, sendCall, this.log).then(notUsed => {
      this.debugLog(`transmit trace end.      [${myCallIndex}]`);
    });        
  }

  private debugLog(message : string) : void {
    if (this.debugLogging) {
      this.log.log(message);
    }
  }

}
